export enum ChannelCodeEnum {
    OAM = 'OmniOAM',
    EMAIL = 'OmniEmail',
    OMNI = 'Omni',
    RPOSC = 'RPOSC_ROAM',
    RPOSCEMAIL = 'RPOSCEMAIL',
}

export enum ApplicationStatusEnum {
    COMPLETED = 'COMPLETED',
    PENDING = 'PENDING',
    FAILED = 'FAILED',
}

export enum AppContextEnum {
    OMNI = 'OMNI',
    RPOS = 'RPOS',
}
