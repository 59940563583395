export interface IAuthResponse {
    isAuth: boolean | null;
}

export interface ICustomerSignUpPayload {
    email: string;
    name: string;
    ucpId: string;
    verificationKey: string;
}
export interface IForgotPasswordRequest {
    username: string;
}
export interface ISetNewPasswordRequest {
    username: string;
    verificationCode: string;
    password: string;
}

export interface IChangePasswordRequest {
    user: any;
    password: string;
    newPassword: string;
}

export enum AccountTypes {
    CHARGE_OFF = 'CHARGE OFF',
    PAID_OFF = 'PAID OFF',
    LOAN_IN_APPLICATION = 'LOAN IN APPLICATION',
    CANCELLED = 'CANCELLED APPLICATION',
    REO = 'REO LOAN',
    TO_BE_DELETED = 'ACCOUNT TO BE DELETED',
    ACTIVE = 'ACTIVE',
    INACTIVE = 'INACTIVE',
    BANKRUPT = 'BANKRUPT',
    REPOSESSION = 'REPOSESSION',
    UNKNOWN = 'UNKNOWN',
}
