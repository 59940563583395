import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ILoginState } from './types';

export const initialState: ILoginState = {
    blocked: false,
    name: '',
    token: '',
    'x-fairstone-identity': '',
};

export const loginSlice = createSlice({
    initialState,
    name: 'login',
    reducers: {
        setBlockedState(state, action: PayloadAction<boolean>) {
            state.blocked = action.payload;
        },
        setLoginState(state, action: PayloadAction<ILoginState>) {
            state.name = action.payload.name;
            state.token = action.payload.token;
            state['x-fairstone-identity'] = action.payload['x-fairstone-identity'];
        },
        setUsername(state, action: PayloadAction<string>) {
            state.name = action.payload;
        },
    },
});
