import { combineReducers } from '@reduxjs/toolkit';

import accountReducer from './modules/account';
import addressReducer from './modules/address';
import applicationReducer from './modules/application';
import bankReducer from './modules/bank';
import creditConsentReducer from './modules/creditConsent';
import customerReducer from './modules/customer';
import demographicReducer from './modules/demographic';
import documentVerificationReducer from './modules/documentVerification';
import housingReducer from './modules/housing';
import incomeReducer from './modules/income';
import loginReducer from './modules/login';
import offersReducer from './modules/offers';
import paymentsReducer from './modules/payments';
import productInsuranceReducer from './modules/productInsurance';

export default combineReducers({
    account: accountReducer,
    address: addressReducer,
    application: applicationReducer,
    bank: bankReducer,
    creditConsent: creditConsentReducer,
    customer: customerReducer,
    demographic: demographicReducer,
    documentVerification: documentVerificationReducer,
    housing: housingReducer,
    income: incomeReducer,
    login: loginReducer,
    offers: offersReducer,
    payments: paymentsReducer,
    productInsurance: productInsuranceReducer,
});
