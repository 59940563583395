import { TAvailableTranslations, TLangTranslations, TLocalesMapping } from '@fairstone/ui/core/providers/Intl';

import en from './en/translation.json';
import fr from './fr/translation.json';

const translations: TAvailableTranslations = {
    en: en,
    fr: fr,
};

export const locales: TLangTranslations[] = ['fr', 'en'];

export const localesMapping: TLocalesMapping = { en: 'fr', fr: 'en' };

export default translations;
