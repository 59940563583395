import { EPaymentFrequency, EPaymentSources } from 'types/payments';

import { AccountTypes } from './types';

export const USERNAME_MAX_LENGTH = 30;
export const PASSWORD_MAX_LENGTH = 30;
export const ACCOUNTNUMBER_MAX_LENGTH = 15;
export const LOAD_LIMIT = 6;

export const PASSWORD_REGEX =
    /^(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z])(?=.*[!@#$%^&*()_,.<>;'":\\|{[}\]\-?~`\/])[a-zA-Z0-9+=!@#$%^&*()_,.<>;'":\\|{[}\]\-?~`\/]{8,30}$/;
export const USERNAME_REGEX = /^[0-9a-zA-Z@._]{3,17}$/;
export const PHONE_REGEX_DECODE = /[\s-()]/g;
export const PHONE_REGEX = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;

export const EMAIL_REGEX = new RegExp(
    /(?:[a-z0-9!#$%&'*+\/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+\/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/
);
export const FIRSTNAME_REGEX = new RegExp(/^[a-zA-Z-' ]{1,30}$/);

export const InaccessibleAccountTypes: AccountTypes[] = [AccountTypes.REO, AccountTypes.REPOSESSION];
export const LimitedAccessAccountTypes: AccountTypes[] = [...InaccessibleAccountTypes, AccountTypes.CHARGE_OFF];

export enum ELangCodes {
    ENGLISH = 'en-CA',
    FRENCH = 'fr-CA',
}

export enum EProvinces {
    AB = 'AB',
    BC = 'BC',
    MB = 'MB',
    NB = 'NB',
    NL = 'NL',
    NS = 'NS',
    NT = 'NT',
    NU = 'NU',
    ON = 'ON',
    PE = 'PE',
    QC = 'QC',
    SK = 'SK',
    YT = 'YT',
}

export const allOTPPaymentSources: EPaymentSources[] = [
    EPaymentSources.CURRENT,
    EPaymentSources.PAST_DUE,
    EPaymentSources.MONTHLY,
];

export const allRecurringSources: EPaymentFrequency[] = [
    EPaymentFrequency.MONTHLY,
    EPaymentFrequency.SEMIMONTHLY,
    EPaymentFrequency.BIWEEKLY,
];

export const MOBILE_VIEW = 'mobile';
export const DESKTOP_VIEW = 'desktop';
