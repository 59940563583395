import { RootState } from 'types/store';

import { incomeSlice } from './reducers';
import { IIncomeState } from './types';

export {
    getEmploymentInfoAsync,
    getRposEmploymentInfoAsync,
    updateEmploymentInfoAsync,
    updateRposEmploymentInfoAsync,
} from './actions';
export { initialEmployment, resetProgress, updateProgress } from './reducers';
export const incomeState = (state: RootState): IIncomeState => state.income;
export const {
    addAdditionalIncome,
    addEmployment,
    removeEmployment,
    updateAdditionalIncome,
    updateCurrentStatus,
    updateEmployment,
} = incomeSlice.actions;
export default incomeSlice.reducer;
