import { accountSlice } from 'store/redux/modules/account/reducers';
import { RootState } from 'types/store';

import { IAccountState } from './types';

export { initialState } from './reducers';

export const selectAccount = (state: RootState): IAccountState => state.account;
export { getAccountSummaryAsync, getCustomerAccountsAsync, getTransactionHistoryAsync } from './actions';

export const { setAccounts, setEncryptedAccountApplicationNumber, setName } = accountSlice.actions;

export default accountSlice.reducer;
