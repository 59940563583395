import TrackJS from '@fairstone-frontend/utils/core/logs/trackjs';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';

import { restAPI } from 'services/api';

import { IAddress, IGetAddressResponsePayload, IResidentialRequest, IUpdateAddressResponsePayload } from './types';

// mock fetch request
export const getAddress = async (): Promise<AxiosResponse<IGetAddressResponsePayload> | null> => {
    let response: AxiosResponse<IGetAddressResponsePayload> | null = null;
    try {
        response = await restAPI.get('/user/address');
    } catch (error: any) {
        TrackJS.track(error);
    }

    return response;
};

export const updateAddress = async (
    address: IAddress
): Promise<AxiosResponse<IUpdateAddressResponsePayload> | null> => {
    let response: AxiosResponse<IUpdateAddressResponsePayload> | null = null;
    try {
        response = await restAPI.put('/user/address', address);
    } catch (error: any) {
        TrackJS.track(error);
    }
    return response;
};

const getResidentialInfo = async (request: IResidentialRequest) => {
    let response: AxiosResponse<IGetAddressResponsePayload> | null = null;
    try {
        response = await restAPI.get('/user/residential-information', {
            params: request,
        });
    } catch (error: any) {
        TrackJS.track(error);
    }

    return response;
};

export const getResidentialInfoAsync = createAsyncThunk(
    'address/getResidentialInfo',
    async (request: IResidentialRequest, { rejectWithValue }) => {
        const response = await getResidentialInfo(request);
        if (response && response?.data) {
            return response?.data;
        }

        return rejectWithValue(response);
    }
);

export const getAddressAsync = createAsyncThunk('address/getAddress', async (_, { rejectWithValue }) => {
    const response = await getAddress();

    if (response && response?.data) {
        return response?.data;
    }

    return rejectWithValue(response);
});

export const updateAddressAsync = createAsyncThunk(
    'address/updateAddress',
    async (address: IAddress, { rejectWithValue }) => {
        const response = await updateAddress(address);
        if (response && response?.data) {
            return response?.data;
        }

        return rejectWithValue(response);
    }
);
