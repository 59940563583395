import { translate } from '@fairstone/ui/core/utils/translate';
import TrackJS from '@fairstone-frontend/utils/core/logs/trackjs';
import { Auth } from 'aws-amplify';
import { Middleware } from 'redux';

import { redirectOptions, ScreenUrlPathsEnum } from 'routes/types';
import { ON_SIGN_OUT_EVENT } from 'store/redux/actions';
import { RootState } from 'types/store';
import { readQueryParam } from 'utils/url';

import { persistConfig } from './store-persist-config';

export const onSignedOutMiddleware: Middleware<
    // eslint-disable-next-line @typescript-eslint/ban-types
    {}, // Most middleware do not modify the dispatch return value
    RootState
> = () => (next) => async (action) => {
    if (action.type === ON_SIGN_OUT_EVENT) {
        try {
            const { key, storage } = persistConfig;
            storage.removeItem(`persist:${key}`);
            await Auth.signOut({ global: true });
        } catch (error: any) {
            TrackJS.track(error);
        }
        window.location.assign(translate(ScreenUrlPathsEnum[readQueryParam('redirectTo', redirectOptions)]));
        return;
    }
    return next(action);
};
