import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ON_RESET_EVENT } from 'store/redux/actions';

import { getDemographicInfoAsync } from './actions';
import { IDemographicInfo, IDemographicResponse, IDemographicState } from './types';

export const initialState: IDemographicState = {
    information: {
        dob: '',
        firstName: '',
        lastName: '',
        phone: '',
    },
    loading: true,
};

export const demographicSlice = createSlice({
    extraReducers: (builder: any) => {
        builder.addCase(getDemographicInfoAsync.pending, (state: IDemographicState) => {
            state.loading = true;
        });
        builder.addCase(
            getDemographicInfoAsync.fulfilled,
            (state: IDemographicState, action: PayloadAction<IDemographicResponse>) => {
                if (action.payload) {
                    state.information = action.payload;
                }
                state.loading = false;
            }
        );
        builder.addCase(getDemographicInfoAsync.rejected, (state: IDemographicState) => {
            state.loading = false;
        });
        builder.addCase(ON_RESET_EVENT, () => initialState);
    },
    initialState,
    name: 'demographicSlice',
    reducers: {
        updateDemographic(state, action: PayloadAction<IDemographicInfo>) {
            state.information = action.payload;
        },
    },
});
