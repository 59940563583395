import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ON_RESET_EVENT } from 'store/redux/actions';

import { getUrlsAndUploadDocumentsAsync } from './actions';
import { DocumentCategoryEnum, DocumentTypeEnum } from './types';

export interface IDocumentSectionVerificationState {
    loading: boolean;
    unknownError: boolean;
    errors: DocumentTypeEnum[];
    requiredDocuments: DocumentTypeEnum[] | null;
}

export const initialState: IDocumentSectionVerificationState = {
    errors: [],
    loading: false,
    requiredDocuments: [DocumentTypeEnum.ID_OTHER],
    unknownError: false,
};

export const documentVerificationSlice = createSlice({
    extraReducers: (builder: any) => {
        builder.addCase(getUrlsAndUploadDocumentsAsync.pending, (state: IDocumentSectionVerificationState) => {
            state.loading = true;
        });

        builder.addCase(
            getUrlsAndUploadDocumentsAsync.fulfilled,
            (state: IDocumentSectionVerificationState, action: PayloadAction<DocumentTypeEnum[]>) => {
                state.errors = action.payload;
                state.loading = false;
            }
        );
        builder.addCase(getUrlsAndUploadDocumentsAsync.rejected, (state: IDocumentSectionVerificationState) => {
            state.loading = false;
            state.unknownError = true;
        });

        builder.addCase(ON_RESET_EVENT, () => initialState);
    },
    initialState,
    name: 'documentVerification',
    reducers: {
        clearRequiredDocuments(state, actions: PayloadAction<DocumentCategoryEnum>) {
            const { requiredDocuments } = state;
            if (requiredDocuments === null) return;

            switch (actions.payload) {
                case DocumentCategoryEnum.ADDRESS:
                    state.requiredDocuments = requiredDocuments!.filter((type) => type != DocumentTypeEnum.ID_OTHER);
                    break;
                case DocumentCategoryEnum.HOUSING:
                    state.requiredDocuments = requiredDocuments!.filter((type) => type != DocumentTypeEnum.RENTER);
                    break;
                case DocumentCategoryEnum.INCOME:
                    state.requiredDocuments = requiredDocuments!.filter((type) =>
                        [DocumentTypeEnum.RENTER, DocumentTypeEnum.ID_OTHER, DocumentTypeEnum.HOUSING_OTHER].includes(
                            type
                        )
                    );
                    break;
                default:
                    state.requiredDocuments = null;
            }
        },
        setRequiredDocuments(state, action: PayloadAction<DocumentTypeEnum[]>) {
            let { requiredDocuments } = state;
            if (requiredDocuments === null) {
                requiredDocuments = [];
            }
            state.requiredDocuments = [...new Set([...requiredDocuments, ...action.payload])];
        },
    },
});
