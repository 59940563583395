import { ChannelCodeEnum } from '../types';

export enum CustomerTypeEnum {
    OWNER = 'Homeowner',
    RENTER = 'Renter',
}

export enum PropertyTypeEnum {
    OWN = 'Owns / Buying Single Family Res. House',
    RENT = 'Rents Apartment',
    NEITHER = 'Parents / Free Housing',
}
export interface IHousingUpdateRequestPayload extends IHousing {
    channelCode: ChannelCodeEnum;
    applicationNumber: string;
}
export interface IHousingGetRequestPayload {
    channelCode: ChannelCodeEnum;
    applicationNumber: string;
}
export interface IHousingState {
    housing: IHousing;
    loading: boolean;
}

export interface IHousing extends IHousingBase {
    customerType: CustomerTypeEnum | null;
}

export interface IHousingBase {
    additionalExpenses: string;
    propertyInformation: IPropertyInformation;
    renterInformation?: IRenterInformation;
}

export interface IPropertyInformation {
    mortgageInformation?: IMortgageInformation;
    propertyType: PropertyTypeEnum;
}

export interface IRenterInformation {
    landLordName: string;
    rentAmount: string;
}

export interface IMortgageInformation {
    annualTaxes: string;
    annualCondoFees: string;
    annualHOInsurance: string;
    expirationDate: string;
    mortgageHolder: string;
    monthlyPayment: string;
    presentValue: string;
    mortgageFreeAndClear: boolean;
}

export interface IHousingGetResponsePayload {
    additionalExpenses: string;
    customerType: string;
    propertyInformation: {
        mortgageInformation: {
            annualTaxes: string;
            annualHOInsurance: string;
            annualCondoFees: string;
            expirationDate?: string;
            mortgageHolder?: string;
            presentValue?: string;
            mortgageFreeAndClear: boolean;
            monthlyPayment?: string;
        };
    };
    renterInformation: {
        landLordName: string;
        rentAmount: string;
    };
}
export interface IHousingUpdateResponsePayload {
    status: string;
}

export interface IHousingInfoRequest {
    accountNumber: string;
    applicationNumber: string;
    channelCode: ChannelCodeEnum;
}

export interface IHousingInfoResponse {
    additionalExpenses: string;
    customerType: string;
    propertyInformation: {
        mortgageInformation: IMortgageInformation;
        propertyType: PropertyTypeEnum;
    };
    rentalInformation: {
        landlordName: string;
        rentAmount: string;
    };
}

export interface IHousingInfoUpdateRequest extends IHousingBase {
    channelCode: ChannelCodeEnum;
    applicationNumber: string;
    accountNumber: string;
    propertyType: PropertyTypeEnum;
}

export interface IHousingInfoUpdateResponse {
    infoUpdated: boolean;
}
