import { SdkResponse } from '@fairstone/functional/core/components/Onfido';
import TrackJS from '@fairstone-frontend/utils/core/logs/trackjs';
import axios, { AxiosError, AxiosInstance, AxiosResponse } from 'axios';

import { DOCPORTAL_API_KEY, DOCPORTAL_API_URL } from 'config/constants';
import { RootState } from 'providers';
import { axiosErrorHandler, axiosSuccessHandler, restAPI } from 'services/api';

export let docportalRestApi: AxiosInstance;

export const createDocportalApiService = (store: RootState): void => {
    docportalRestApi = axios.create({
        baseURL: DOCPORTAL_API_URL,
    });

    docportalRestApi.interceptors.response.use(
        (response) => axiosSuccessHandler(response),
        (error) => axiosErrorHandler(error, store)
    );
};

interface IAxiosErrorResponse {
    errorType: string;
    status: number;
}

interface ISecurityHeaders {
    Authorization: string;
    'x-api-key': string;
}

export const getOnfidoSDKTokenUrl = (): string => `${DOCPORTAL_API_URL}/onfido/sdk-token`;
export const getOnfidoSecurityHeaders = (accessToken: string): ISecurityHeaders => ({
    Authorization: `Bearer ${accessToken}`,
    'x-api-key': DOCPORTAL_API_KEY,
});

interface IIDVerificationRequest {
    applicationNumber: string;
    branchNumber: string;
    locale: string;
}

export const getDocportalJWTToken = async (request: IIDVerificationRequest): Promise<AxiosResponse | null> => {
    let response: AxiosResponse | null = null;
    try {
        response = await restAPI.post('/user/start-onfido-process', request);
    } catch (e: any) {
        TrackJS.track({ e });
    }

    return response;
};

export const createOnfidoCheck = async (
    applicantId: string,
    data: SdkResponse,
    headers: ISecurityHeaders
): Promise<AxiosResponse | IAxiosErrorResponse> => {
    let response = null;
    try {
        response = await docportalRestApi.post(`/onfido/check/${applicantId}`, data, { headers: { ...headers } });
    } catch (e: any) {
        const error = e as AxiosError;
        response = { errorType: 'error', status: error.response?.status || 500 };

        TrackJS.track({ e });
    }

    return response;
};
