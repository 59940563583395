import TrackJS from '@fairstone-frontend/utils/core/logs/trackjs';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';

import { restAPI, roamAPI } from 'services/api';
import { getCognitoSub } from 'store/cache/tokens';

import { setAlreadySubmitted } from '../application';

import { IAccountDetails, IGetLeadOfferResponse, IOfferResponsePayload, LeadOfferRequest, OfferRequest } from './types';

const getOffers = async (offerRequest: OfferRequest) => {
    let response: AxiosResponse<IOfferResponsePayload> | null = null;
    let payload = {};

    const excludeCodes = [400, 500];

    if (offerRequest.skipCheck) {
        payload = { ...offerRequest };
    }
    try {
        response = (await restAPI.get('/offer/latest', {
            params: {
                ...payload,
            },
            validateStatus: (status: number) => status < 400 || excludeCodes.includes(status), //By pass axios error handler
        })) as AxiosResponse<IOfferResponsePayload>;

        if (excludeCodes.includes(response?.status)) {
            TrackJS.track((response as AxiosResponse).data.error);
            return null;
        }
    } catch (error: any) {
        TrackJS.track(error);
    }

    return response;
};

const getAccounts = async () => {
    let response: AxiosResponse<Array<IAccountDetails>> | null = null;
    try {
        const cognitoSub = await getCognitoSub();
        response = await roamAPI.get(`/account/${cognitoSub}`);
    } catch (error: any) {
        TrackJS.track(error);
    }

    return response;
};

const getLeadOffers = async (offerRequest: LeadOfferRequest) => {
    let response: AxiosResponse<IGetLeadOfferResponse> | null = null;
    const params = {
        visionAccountNumbers: JSON.stringify(offerRequest.accountList),
    };
    try {
        response = await restAPI.get('/offer/lead-details', {
            params,
        });
    } catch (error: any) {
        TrackJS.track(error);
    }

    return response;
};

export const getOffersAsync = createAsyncThunk(
    'offers/fetchOffers',
    async (offerRequest: OfferRequest, { dispatch, rejectWithValue }) => {
        const response = await getOffers(offerRequest);

        if (response && response?.data) {
            dispatch(setAlreadySubmitted(response?.data.applicationInBranch));
            return response?.data;
        }

        return rejectWithValue(response);
    }
);

export const getLeadOffersAsync = createAsyncThunk('offers/fetchLeadOffers', async (_, { rejectWithValue }) => {
    //get account async
    const accountsResponse = await getAccounts();

    if (!accountsResponse || accountsResponse?.data.length == 0) {
        return { hasOffer: false };
    }

    const offerRequest: LeadOfferRequest = {
        accountList: accountsResponse.data.map((account) => account.accountNumber),
    };

    const response = await getLeadOffers(offerRequest);

    if (response && response?.data) {
        return response?.data;
    }
    return rejectWithValue(response);
});
