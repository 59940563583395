import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ON_RESET_EVENT } from 'store/redux/actions';

import { getBankInfoAsync, updateBankAsync, updateRposBankAsync } from './actions';
import { IBankState, IGetBankPayload, IPostBankPayload } from './types';

export const initialState: IBankState = {
    bank: {
        accountNickname: '',
        accountNumber: '',
        institutionNumber: '',
        transitNumber: '',
    },
    infoExists: null,
    loading: true,
};

export const bankSlice = createSlice({
    extraReducers: (builder: any) => {
        builder.addCase(getBankInfoAsync.pending, (state: IBankState) => {
            state.loading = true;
        });
        builder.addCase(getBankInfoAsync.fulfilled, (state: IBankState, action: PayloadAction<IGetBankPayload>) => {
            state.loading = false;
            if (action.payload) {
                state.infoExists = action.payload.infoExists;
            }
        });
        builder.addCase(getBankInfoAsync.rejected, (state: IBankState) => {
            state.loading = false;
        });
        builder.addCase(updateBankAsync.pending, (state: IBankState) => {
            state.loading = true;
        });
        builder.addCase(updateBankAsync.fulfilled, (state: IBankState, action: PayloadAction<IPostBankPayload>) => {
            state.bank = { ...action.payload.bank };
            state.infoExists = action.payload.updateStatus;
            state.loading = false;
        });
        builder.addCase(updateBankAsync.rejected, (state: IBankState) => {
            state.loading = false;
        });
        builder.addCase(updateRposBankAsync.pending, (state: IBankState) => {
            state.loading = true;
        });
        builder.addCase(updateRposBankAsync.fulfilled, (state: IBankState, action: PayloadAction<IPostBankPayload>) => {
            state.bank = { ...action.payload.bank };
            state.infoExists = action.payload.updateStatus;
            state.loading = false;
        });
        builder.addCase(updateRposBankAsync.rejected, (state: IBankState) => {
            state.loading = false;
        });
        builder.addCase(ON_RESET_EVENT, () => initialState);
    },
    initialState,
    name: 'bank',
    reducers: {
        setLoading(state, action: PayloadAction<boolean>) {
            state.loading = action.payload;
        },
    },
});
