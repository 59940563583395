import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { isEmpty } from 'lodash';

import { FlowEnum, updateApplicationFlow } from 'routes/FlowManager';
import { ScreenUrlPathsEnum, StepNameEnum } from 'routes/types';
import { ON_RESET_EVENT } from 'store/redux/actions';
import { ApplicationStatusEnum, ChannelCodeEnum } from 'store/redux/modules/types';

import {
    createNonGloApplicationAsync,
    createRposApplicationAsync,
    getLatestStepAsync,
    getRposLatestStepAsync,
    updateLatestStepAsync,
} from './actions';
import { IApplicationState, INonGloApplicationResponse, IRposApplicationResponse, IUserLastState } from './types';

export const initialState: IApplicationState = {
    // must be null to allow override
    adplacementId: null,
    alreadySubmitted: false,
    applicationNumber: '',
    branchNumber: '',
    // must be null to allow override
    channelCode: null,
    connectedToFlinks: false,
    error: false,
    featureFlags: null,
    flow: FlowEnum.GLO,
    hasRanOmniContext: false,
    isAuthenticated: false,
    isLatestStepVisited: true,
    latestStep: '',
    loading: false,
    visionAccountNumber: '',
};

export const applicationSlice = createSlice({
    extraReducers: (builder: any) => {
        builder.addCase(
            createNonGloApplicationAsync.fulfilled,
            (state: IApplicationState, action: PayloadAction<INonGloApplicationResponse>) => {
                const { applicationNumber, branchNumber } = action.payload;
                if (isEmpty(applicationNumber) || isEmpty(branchNumber)) state.error = true;
                else {
                    state.applicationNumber = applicationNumber;
                    state.branchNumber = branchNumber;
                }

                state.loading = false;
            }
        );
        builder.addCase(createNonGloApplicationAsync.rejected, (state: IApplicationState) => {
            state.error = true;
            state.loading = false;
        });
        builder.addCase(
            getLatestStepAsync.fulfilled,
            (state: IApplicationState, action: PayloadAction<IUserLastState>) => {
                state.userLastState = { ...action.payload };
                if (state.userLastState?.status) {
                    state.isLatestStepVisited = false;
                    if (state.userLastState.status == ApplicationStatusEnum.COMPLETED) {
                        state.latestStep = ScreenUrlPathsEnum.THANK_YOU;
                        state.alreadySubmitted = true;
                    } else {
                        state.latestStep = ScreenUrlPathsEnum[state.userLastState.stepName as StepNameEnum];
                    }
                }
                state.loading = false;
            }
        );
        builder.addCase(getLatestStepAsync.pending, (state: IApplicationState) => {
            state.loading = true;
        });
        builder.addCase(
            getRposLatestStepAsync.fulfilled,
            (state: IApplicationState, action: PayloadAction<IUserLastState>) => {
                if (state.alreadySubmitted) {
                    state.latestStep = ScreenUrlPathsEnum.THANK_YOU;
                } else {
                    state.userLastState = { ...action.payload };
                    if (state.userLastState?.status) {
                        state.isLatestStepVisited = false;
                        if (state.userLastState.status == ApplicationStatusEnum.COMPLETED) {
                            state.latestStep = ScreenUrlPathsEnum.THANK_YOU;
                            state.alreadySubmitted = true;
                        } else {
                            state.latestStep = ScreenUrlPathsEnum[state.userLastState.stepName as StepNameEnum];
                        }
                    }
                }
                state.loading = false;
            }
        );
        builder.addCase(getRposLatestStepAsync.pending, (state: IApplicationState) => {
            state.loading = true;
        });
        builder.addCase(updateLatestStepAsync.rejected, (state: IApplicationState) => {
            state.error = false;
        });
        builder.addCase(
            createRposApplicationAsync.fulfilled,
            (state: IApplicationState, action: PayloadAction<IRposApplicationResponse>) => {
                const { applicationId, branchNumber } = action.payload;
                if (isEmpty(applicationId) || isEmpty(branchNumber)) state.error = true;
                else {
                    state.applicationNumber = applicationId;
                    state.branchNumber = branchNumber;
                }
                state.loading = false;
            }
        );
        builder.addCase(createRposApplicationAsync.rejected, (state: IApplicationState) => {
            state.error = true;
        });
        builder.addCase(ON_RESET_EVENT, () => initialState);
    },
    initialState,
    name: 'application',
    reducers: {
        setAdplacementId(state, action: PayloadAction<string>) {
            state.adplacementId = action.payload;
        },
        setAlreadySubmitted(state, action: PayloadAction<boolean>) {
            state.alreadySubmitted = action.payload;
        },
        setApplicationError(state, action: PayloadAction<boolean>) {
            state.error = action.payload;
        },
        setApplicationFlow(state, action: PayloadAction<FlowEnum>) {
            state.flow = action.payload;

            updateApplicationFlow(action.payload);
        },
        setApplicationNumber(state, action: PayloadAction<string>) {
            if (isEmpty(state.applicationNumber)) {
                //prevent overwriting
                state.applicationNumber = action.payload;
            }
        },
        setBranchNumber(state, action: PayloadAction<string>) {
            state.branchNumber = action.payload;
        },
        setChannelCode(state, action: PayloadAction<ChannelCodeEnum>) {
            state.channelCode = action.payload;
        },
        setConnectedToFlinks(state, action: PayloadAction<boolean>) {
            state.connectedToFlinks = action.payload;
        },
        setFeatureFlags(state, action: PayloadAction<Record<string, string>>) {
            state.featureFlags = action.payload;
        },
        setHasRanOmniContext(state, action: PayloadAction<boolean>) {
            state.hasRanOmniContext = action.payload;
        },
        setIsAuthenticated(state, action: PayloadAction<boolean>) {
            state.isAuthenticated = action.payload;
        },
        setLastStepVisited(state) {
            state.isLatestStepVisited = true;
        },
        setLatestStep(state, action: PayloadAction<StepNameEnum>) {
            state.latestStep = ScreenUrlPathsEnum[action.payload];
            if (state.latestStep) {
                state.isLatestStepVisited = false;
            }
        },
    },
});
