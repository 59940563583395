import { ChannelCodeEnum } from '../types';

export interface IProductServiceResponse {
    consolidatedDebt: number;
    amount: number;
    products: IProductResponse[];
}
export interface IProductServiceUpdateResponse {
    updated: boolean;
}

export interface IProductServicePayload {
    amount: number;
    channelCode: ChannelCodeEnum;
    applicationNumber: string;
    isGlo: boolean;
}

export interface IProductServiceUpdatePayload {
    amount: number;
    channelCode: ChannelCodeEnum;
    applicationNumber: string;
    term: number;
    overrideFlag: boolean;
    isGlo: boolean;
}

export interface IProductServiceUpdateResponsePayload {
    status: boolean;
}

export interface IProductResponse {
    insuranceOptedPremiumAmount: number;
    disabilityInsurancePremiumOptedAmount: number;
    lifeInsuranceOptedPremiumAmount: number;
    insuranceOptedFlag: ProductInsuranceEnum;
    disabilityInsuranceOptedFlag: ProductInsuranceEnum;
    lifeInsuranceOptedFlag: ProductInsuranceEnum;
    term: number;
    monthlyPaymentWithInsurance: number;
    monthlyPaymentWithoutInsurance: number;
    overrideFlag: boolean;
}

export enum ProductInsuranceEnum {
    NOT_COVERED = '0',
    COVERED = '1',
    NO_LONGER_COVERED = '2',
}

export interface IRPOSProductOfferRequest {
    accountNumber: string;
    applicationNumber: string;
    channelCode: ChannelCodeEnum;
    amount: number;
}

export interface IRPOSOptionalProductRequest {
    accountNumber: string;
    applicationNumber: string;
    channelCode: ChannelCodeEnum;
}

export interface IRposOptionalProductPayload {
    productType: string;
    optedFlag: string;
}

export interface IRPOSUpdateOptionalProductRequest extends IRPOSOptionalProductRequest {
    optionalProduct: IRposOptionalProductPayload[];
}

export interface IRPOSProductOffer {
    amount: number;
    consolidatedDebt: number;
    monthlyPaymentWithInsurance: number;
    monthlyPaymentWithoutInsurance: number;
    offerType: string;
    overrideFlag: boolean;
    productID: string;
    productOfferType: string;
    rate: number;
    term: number;
}

export type IRPOSProductOfferResponse = IRPOSProductOffer[];

export type IRposOptionalProduct = IRPOSOptionalProductResponse;

export interface IRposOptionalInsuranceProduct {
    productType: string;
    premium: string;
    opted: string;
    translate: string;
}

export interface IRPOSOptionalProductResponse {
    optionalProduct: IRposOptionalInsuranceProduct[];
    monthlyPaymentWithInsurance: number;
    monthlyPaymentWithoutInsurance: number;
}

export interface IRPOSProductUpdateRequest extends IRPOSOptionalProductRequest {
    product: IRPOSProduct;
}

export interface IRPOSProduct {
    term: number;
    amount: number;
    offerType?: string;
    overrideFlag?: boolean;
    productOfferType?: string;
}

export enum InsuranceType {
    LifeInsurance = 'LIFE',
    Disability = 'DIS',
    Unemployment = 'IUI',
}

export enum ProductInsurancesProgressEnum {
    REVIEW = 'REVIEW',
    INSURANCES = 'INSURANCES',
}
