import TrackJS from '@fairstone-frontend/utils/core/logs/trackjs';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';

import { oamAPI } from 'services/api';
import { onSignedOut } from 'store/redux/actions';
import { IAccountSummary } from 'types/account';
import { ITransactionHistory } from 'types/transactionHistory';

import { setBlockedState } from '../login';

import { IGetCustomerAccountsResponse, IGetTransactionHistoryRequest } from './types';

export const getCustomerAccounts = async (): Promise<AxiosResponse<IGetCustomerAccountsResponse> | null> => {
    let response: AxiosResponse<IGetCustomerAccountsResponse> | null = null;
    try {
        response = await oamAPI.get('/oam/private/customer/account');
    } catch (error: any) {
        TrackJS.track(error);
    }

    return response;
};

export const getCustomerAccountsAsync = createAsyncThunk(
    'account/getCustomerAccounts',
    async (_, { rejectWithValue }) => {
        const response = await getCustomerAccounts();

        if (response && response?.data) {
            return response?.data;
        }

        return rejectWithValue(response);
    }
);

export const getAccountSummary = async (
    accountApplicationNumber: string
): Promise<AxiosResponse<IAccountSummary> | null> => {
    let response: AxiosResponse<IAccountSummary> | null = null;
    try {
        response = await oamAPI.get('/oam/private/account', {
            params: {
                accountApplicationNumber,
                excludedCodes: [401, 403],
            },
        });

        // Enable this fake call when you want to mock BE
        // response = (await mockGetAccountSummaryCall()) as AxiosResponse<IAccountSummary>;
    } catch (error: any) {
        TrackJS.track(error);
        return error;
    }

    return response;
};

export const getAccountSummaryAsync = createAsyncThunk(
    'account/getAccountSummary',
    async (accountApplicationNumber: string, { dispatch, rejectWithValue }) => {
        const response = await getAccountSummary(accountApplicationNumber);

        if (response?.request?.status === 401) {
            dispatch(onSignedOut());
            dispatch(setBlockedState(true));
        }

        if (response && response?.data) {
            return response?.data;
        }

        return rejectWithValue(response);
    }
);

export const getTransactionHistory = async ({
    accountNumber,
    moreHistoryDay,
}: IGetTransactionHistoryRequest): Promise<AxiosResponse<ITransactionHistory> | null> => {
    let response: AxiosResponse<ITransactionHistory> | null = null;
    try {
        response = await oamAPI.get(`/oam/private/account/history`, {
            params: {
                accountNumber: accountNumber,
                moreHistoryDay: moreHistoryDay,
            },
        });

        // Enable this fake call when you want to mock BE
        // response = (await mockGetTransactionHistoryCall()) as AxiosResponse<ITransactionHistory>;
    } catch (error: any) {
        TrackJS.track(error);
    }

    return response;
};

export const getTransactionHistoryAsync = createAsyncThunk(
    'account/getTransactionHistory',
    async (request: IGetTransactionHistoryRequest, { rejectWithValue }) => {
        const response = await getTransactionHistory(request);

        if (response?.data) {
            return response?.data;
        }

        return rejectWithValue(response);
    }
);
