import { Auth } from '@aws-amplify/auth';
import TrackJS from '@fairstone-frontend/utils/core/logs/trackjs';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';

import { oamAPI } from 'services/api';
import { ISegment } from 'types/customer';

import {
    EResponseCodes,
    IResendCodeRequest,
    IRetrieveUsernameResponse,
    IVerifyCodeRequest,
    IVerifyCustomerRequest,
    IVerifyCustomerResponse,
} from './types';

const retrieveUsername = async (request: IVerifyCustomerRequest) => {
    let response: AxiosResponse<IRetrieveUsernameResponse> | null = null;

    try {
        response = await oamAPI.post('/oam/public/account/forgot', request);
    } catch (error: any) {
        const { response: errorResponse } = error;
        const errorsToIntercept = Object.values(EResponseCodes);

        if (errorsToIntercept.includes(errorResponse?.status)) {
            response = errorResponse;
        }

        TrackJS.track(error);
    }

    return response?.data || null;
};

const verifyCustomer = async (request: IVerifyCustomerRequest) => {
    let response: AxiosResponse<IVerifyCustomerResponse> | null = null;

    try {
        response = await oamAPI.post('/oam/public/account/validate', request);
    } catch (error: any) {
        const { response: errorResponse } = error;
        const errorsToIntercept = Object.values(EResponseCodes);

        if (errorsToIntercept.includes(errorResponse?.status)) {
            response = errorResponse;
        }

        TrackJS.track(error);
    }

    return response?.data || null;
};

const verifyCode = async (request: IVerifyCodeRequest) => {
    let response: any | null = null;

    try {
        response = await Auth.confirmSignUp(request.username, request.verificationCode);
    } catch (error: any) {
        response = { error: error.code };
    }
    return response || null;
};

const resendCode = async (request: IResendCodeRequest) => {
    let response: any | null = null;

    try {
        response = await Auth.resendSignUp(request.username);
    } catch (error: any) {
        response = { error: error.code };
    }

    return response || null;
};

export const verifyCustomerAsync = createAsyncThunk(
    'customer/verifyCustomer',
    async (request: IVerifyCustomerRequest, { rejectWithValue }) => {
        const responseData = await verifyCustomer(request);

        if (responseData) {
            if (!responseData?.error) return responseData;
        }

        return rejectWithValue(responseData);
    }
);

export const retrieveUsernameAsync = createAsyncThunk(
    'customer/retrieveUsername',
    async (request: IVerifyCustomerRequest, { rejectWithValue }) => {
        const responseData = await retrieveUsername(request);

        if (responseData) {
            if (!responseData?.error) return responseData;
        }

        return rejectWithValue(responseData);
    }
);

export const verifyCodeAsync = createAsyncThunk(
    'customer/verifyCode',
    async (request: IVerifyCodeRequest, { rejectWithValue }) => {
        const responseData = await verifyCode(request);

        if (responseData) {
            if (!responseData?.error) return responseData;
        }

        return rejectWithValue(responseData.error);
    }
);

export const resendCodeAsync = createAsyncThunk(
    'customer/resendCode',
    async (request: IResendCodeRequest, { rejectWithValue }) => {
        const responseData = await resendCode(request);

        if (responseData) {
            if (!responseData?.error) return responseData;
        }

        return rejectWithValue(responseData.error);
    }
);

const retrieveBannerDetails = async () => {
    let response: AxiosResponse<ISegment[]> | null = null;

    try {
        response = await oamAPI.get('/oam/private/customer/banner');
    } catch (error: any) {
        TrackJS.track(error);
    }

    return response?.data || null;
};

export const retrieveBannerDetailsAsync = createAsyncThunk(
    'customer/retrieveBannerDetails',
    async (_, { rejectWithValue }) => {
        const response = await retrieveBannerDetails();

        return response || rejectWithValue(response);
    }
);
