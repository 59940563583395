import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ON_RESET_EVENT } from 'store/redux/actions';

import { performRPOSCreditCheckAsync, postCreditConsentAsync, postSoftCreditConsentAsync } from './actions';
import { CreditCheckStatusEnum, ICreditConsentResponsePayload, IRPOSCreditCheckResponse } from './types';

export interface ICreditConsentState {
    loading: boolean;
    status: CreditCheckStatusEnum | null;
    statusSoft: CreditCheckStatusEnum | null;
    submitted: boolean;
}

export const initialState: ICreditConsentState = {
    loading: false,
    status: null,
    statusSoft: null,
    submitted: false,
};

export const creditConsentSlice = createSlice({
    extraReducers: (builder: any) => {
        builder.addCase(postCreditConsentAsync.pending, (state: ICreditConsentState) => {
            state.loading = true;
        });
        builder.addCase(
            postCreditConsentAsync.fulfilled,
            (state: ICreditConsentState, action: PayloadAction<ICreditConsentResponsePayload>) => {
                state.loading = false;
                if (action.payload) {
                    state.status = action.payload.creditCheck;
                    state.submitted = true;
                }
            }
        );
        builder.addCase(postCreditConsentAsync.rejected, (state: ICreditConsentState) => {
            state.status = CreditCheckStatusEnum.FAIL;
            state.loading = false;
            state.submitted = true;
        });
        builder.addCase(postSoftCreditConsentAsync.pending, (state: ICreditConsentState) => {
            state.loading = true;
        });
        builder.addCase(
            postSoftCreditConsentAsync.fulfilled,
            (state: ICreditConsentState, action: PayloadAction<ICreditConsentResponsePayload>) => {
                state.loading = false;
                if (action.payload) {
                    state.statusSoft = action.payload.creditCheck;
                    state.submitted = true;
                }
            }
        );
        builder.addCase(postSoftCreditConsentAsync.rejected, (state: ICreditConsentState) => {
            state.statusSoft = CreditCheckStatusEnum.FAIL;
            state.loading = false;
            state.submitted = true;
        });
        builder.addCase(performRPOSCreditCheckAsync.pending, (state: ICreditConsentState) => {
            state.loading = true;
        });
        builder.addCase(
            performRPOSCreditCheckAsync.fulfilled,
            (state: ICreditConsentState, action: PayloadAction<IRPOSCreditCheckResponse>) => {
                state.loading = false;
                if (action.payload) {
                    state.status = action.payload.creditCheck;
                    state.submitted = true;
                }
            }
        );
        builder.addCase(performRPOSCreditCheckAsync.rejected, (state: ICreditConsentState) => {
            state.status = CreditCheckStatusEnum.FAIL;
            state.loading = false;
            state.submitted = true;
        });
        builder.addCase(ON_RESET_EVENT, () => initialState);
    },
    initialState,
    name: 'credit-consent',
    reducers: {
        setSubmitted(state, action: PayloadAction<boolean>) {
            state.submitted = action.payload;
        },
    },
});
