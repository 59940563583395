import { ChannelCodeEnum } from '../types';

export interface ICreditConsentRequestPayload {
    adplacementId?: string | null;
    offerAmount: number;
    channelCode: ChannelCodeEnum | null;
}
export interface ISoftCreditConsentRequestPayload {
    applicationNumber: string;
    channelCode: ChannelCodeEnum | null;
}
export interface ICreditConsentResponsePayload {
    creditCheck: CreditCheckStatusEnum;
    applicationNumber: string;
}
export interface ISoftCreditConsentResponsePayload {
    creditCheck: CreditCheckStatusEnum;
}

export enum CreditCheckStatusEnum {
    PASS = 'PASS',
    FAIL = 'FAIL',
    PENDING = 'PENDING',
    REJECTED = 'REJECTED',
}

export enum EApplicationStatusMapping {
    PASS = 'approved',
    REJECTED = 'declined',
    PENDING = 'pending',
    FAIL = 'error',
}

export interface IRPOSCreditCheckRequest {
    applicationNumber: string;
    accountNumber: string;
    channelCode: ChannelCodeEnum | null;
}

export interface IRPOSCreditCheckResponse {
    creditCheck: CreditCheckStatusEnum;
}
