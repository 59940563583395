// test if sessionStorage is working
// we need to test this in case we are in Safari Private Mode
// if we don't have sessionStorage Cognito will use test if it has Localstorage and fall back to in memory: does not matter since in Private Mode it will be cleared
// we need a full test (set and remove); if we just check (window.sessionStorage) we get true in Safari in Private Mode
export const isSessionStorageAvailable = (): boolean => {
    try {
        sessionStorage.setItem('test-oam-session', '1');
        sessionStorage.removeItem('test-oam-session');
    } catch (exception) {
        return false;
    }

    return true;
};
