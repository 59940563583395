export interface ILoginPayload {
    username: string;
    password: string;
}

export interface IUser {
    attributes: {
        name: string;
    };
}

export enum EAuthHeaders {
    FAIRSTONE_IDENTITY = 'x-fairstone-identity',
}

export const ErrorTypesByException = {
    ForbiddenException: 'credentialsErrorMessage',
    InternalErrorException: 'genericErrorMessage',
    IsBlocked: 'isBlocked',
    NoCustomerInformationFound: 'noCustomerInformationFound',
    NotAuthorizedException: 'credentialsErrorMessage',
    TooManyFailedAttemptsException: 'tooManyFailedAttemptsException',
    UserLambdaValidationException: 'genericErrorMessage',
    UserNotConfirmedException: 'userNotConfirmedException',
    UserNotFoundException: 'credentialsErrorMessage',
};
