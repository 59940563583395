import { LogRocket } from '@fairstone-frontend/utils/core/logs';
import { configureStore } from '@reduxjs/toolkit';
import { FLUSH, PAUSE, PERSIST, persistReducer, PURGE, REGISTER, REHYDRATE } from 'redux-persist';

import { onSignedOutMiddleware } from 'store/redux/middleware';

import rootReducer from './reducers';
import { persistConfig } from './store-persist-config';

const persistedReducer = persistReducer(persistConfig, rootReducer);
export const store: any = configureStore({
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
            },
        })
            .concat(onSignedOutMiddleware)
            .concat(LogRocket.reduxMiddleware()),
    reducer: persistedReducer,
});
