import { getKeyTranslations } from '@fairstone/ui/core/providers/Intl/locales/translate';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import qs from 'query-string';
import xss, { IFilterXSSOptions } from 'xss';

const xssConfig: IFilterXSSOptions = {
    escapeHtml: (f) => f,
    stripIgnoreTag: true, // filter out all HTML not in the whitelist
    stripIgnoreTagBody: ['script'], // the script tag is a special case, we need to filter out its content
    whiteList: {}, // empty, means filter out all tags
};

export interface IQueryParamValues<T> {
    defaultValue: T;
    whitelist?: Array<T>;
}

export const getQueryParams = (search: any = null): qs.ParsedQuery<string> =>
    search ? qs.parse(search) : qs.parse(window.location.search);

export const readQueryParam = <T = ''>(key: string, options: IQueryParamValues<T>, search: any = null): T => {
    const query = getQueryParams(search);
    let result = get<any, any, T>(query, key, options.defaultValue)!;
    result = xss(result, xssConfig);
    if (!isEmpty(options.whitelist) && !options.whitelist!.includes(result)) {
        result = options.defaultValue;
    }
    return result;
};

export const setRedirectPath = (currentUri: string): Record<string, string> => {
    const redirectPath = getKeyTranslations(currentUri);
    let redirectFullPath = redirectPath;
    const parsedQs = getQueryParams();

    if (!isEmpty(parsedQs)) {
        const filteredQs = Object.fromEntries(Object.entries(parsedQs).filter(([key]) => !key.includes('redirectTo')));
        const filteredQsStringify = isEmpty(filteredQs) ? '' : `?${qs.stringify(filteredQs)}`;
        redirectFullPath = Object.keys(redirectPath).reduce(
            (acc, key) => ({ ...acc, [key]: `${redirectPath[key]}${filteredQsStringify}` }),
            {}
        );
    }

    return redirectFullPath;
};
