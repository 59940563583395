import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import capitalize from 'lodash/capitalize';

import { ON_RESET_EVENT } from 'store/redux/actions';
import { ISegment } from 'types/customer';

import { getLeadOffersAsync, getOffersAsync } from './actions';
import { IGetLeadOfferResponse } from './types';

export interface IOfferResponse {
    customerName: string;
    amount: number;
    expirationDate: number;
    consolidatedDebt: number;
    isGlo: boolean;
    bannerDetails: ISegment;
}

export interface IOffersState {
    customerName: string;
    visionAccountNumber: string;
    loading: boolean;
    offers: Omit<IOfferResponse, 'customerName'> | null;
    isGlo: boolean;
}

export const initialState: IOffersState = {
    customerName: '',
    isGlo: true,
    loading: true,
    offers: null,
    visionAccountNumber: '',
};

export const offersSlice = createSlice({
    extraReducers: (builder: any) => {
        builder.addCase(getOffersAsync.pending, (state: any) => {
            state.loading = true;
        });
        builder.addCase(getOffersAsync.fulfilled, (state: any, action: PayloadAction<IOfferResponse>) => {
            let offers = null;

            if (action.payload) {
                offers = {
                    amount: action.payload.amount,
                    bannerDetails: action.payload.bannerDetails,
                    consolidatedDebt: action.payload.consolidatedDebt,
                    expirationDate: action.payload.expirationDate * 1000,
                };
                state.customerName = capitalize(action.payload.customerName);
                state.isGlo = action.payload.isGlo;
            }
            state.loading = false;
            state.offers = offers;
        });
        builder.addCase(getOffersAsync.rejected, (state: any) => {
            state.loading = false;
        });
        builder.addCase(getLeadOffersAsync.pending, (state: any) => {
            state.loading = true;
        });
        builder.addCase(getLeadOffersAsync.fulfilled, (state: any, action: PayloadAction<IGetLeadOfferResponse>) => {
            let offers = null;
            if (action.payload && action.payload.hasOffer) {
                offers = {
                    amount: action.payload.offerDetails.offerAmount,
                    consolidatedDebt: 0,
                    expirationDate: action.payload.offerDetails.offerExpirationDate,
                };
                state.customerName = capitalize(action.payload.customerName);
                state.visionAccountNumber = action.payload.visionAccountNumber;
                state.isGlo = true;
            } else {
                state.customerName = '';
                state.visionAccountNumber = '';
            }
            state.loading = false;
            state.offers = offers;
        });
        builder.addCase(getLeadOffersAsync.rejected, () => {
            initialState;
        });
        builder.addCase(ON_RESET_EVENT, () => initialState);
    },
    initialState,
    name: 'offers',
    reducers: {
        setIsGloOffer(state, action: PayloadAction<boolean>) {
            state.isGlo = action.payload;
        },
        setVisionAccountNumber(state, action: PayloadAction<string>) {
            state.visionAccountNumber = action.payload;
        },
    },
});
