import axios, { AxiosHeaders, AxiosInstance, AxiosResponse, RawAxiosRequestHeaders } from 'axios';
import { isEmpty } from 'lodash';

import { API_URL, OAM_URL, ROAM_API_KEY, ROAM_API_URL } from 'config/constants';
import { getAuthTokens, getCredentialTokens, getRoamAuthTokens } from 'store/cache/tokens';
import { onSignedOut } from 'store/redux/actions';
import { setApplicationError } from 'store/redux/modules/application';

import { RootState } from '../providers/index';

export const axiosErrorHandler = async (error: AxiosResponse<any>, store: RootState): Promise<any> => {
    const { dispatch } = store;
    const statusCode = error.request.status;
    const url = error.request.responseURL;
    const rejectedPromise = Promise.reject({ ...error });

    if (String(url).includes('step/latest')) return rejectedPromise;

    const excludedCodes = error.config.params?.excludedCodes || [];
    if (excludedCodes.includes(statusCode)) return rejectedPromise;

    const { accessToken } = await getCredentialTokens();

    switch (statusCode) {
        case 400:
            dispatch(setApplicationError(true));
            break;
        case 401:
        case 403:
            !isEmpty(accessToken) && dispatch(onSignedOut());
            break;
        case 500:
            dispatch(setApplicationError(true));
            break;
        default:
            break;
    }

    return rejectedPromise;
};

export const axiosSuccessHandler = (response: AxiosResponse<any>): any => Promise.resolve({ ...response });

export let restAPI: AxiosInstance;
export let roamAPI: AxiosInstance;
export let oamAPI: AxiosInstance;

export const createApiService = (store: RootState): void => {
    restAPI = axios.create({
        baseURL: API_URL,
    });

    restAPI.interceptors.request.use(async (config) => {
        config.headers = { ...config.headers, ...(await getAuthTokens()) } as RawAxiosRequestHeaders as AxiosHeaders;
        return config;
    });

    restAPI.interceptors.response.use(
        (response) => axiosSuccessHandler(response),
        (error) => axiosErrorHandler(error, store)
    );
};

export const createOAMApiService = (store: RootState): void => {
    oamAPI = axios.create({
        baseURL: OAM_URL,
    });

    oamAPI.interceptors.request.use(async (config) => {
        config.headers = { ...config.headers, ...(await getAuthTokens()) } as RawAxiosRequestHeaders as AxiosHeaders;
        return config;
    });

    oamAPI.interceptors.response.use(
        (response) => axiosSuccessHandler(response),
        (error) => axiosErrorHandler(error, store)
    );
};

export const createRoamApiService = (store: RootState): void => {
    roamAPI = axios.create({
        baseURL: ROAM_API_URL,
    });

    roamAPI.interceptors.request.use(async (config) => {
        config.headers = {
            ...config.headers,
            ...(await getRoamAuthTokens()),
            'x-api-key': ROAM_API_KEY,
        } as RawAxiosRequestHeaders as AxiosHeaders;
        return config;
    });

    roamAPI.interceptors.response.use(
        (response) => axiosSuccessHandler(response),
        (error) => axiosErrorHandler(error, store)
    );
};
