import TrackJS from '@fairstone-frontend/utils/core/logs/trackjs';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';

import { oamAPI } from 'services/api';
import { EPaymentTypes, IPayments } from 'types/payments';
import { buildOTPEditPayload, buildOTPPayload, buildRecurringPayload } from 'utils/payments';

import { IDeletePaymentRequest, IEditPaymentRequest, ISetupNewPaymentRequest } from './types';

export const getPayments = async (encryptedAccountNumber: string): Promise<AxiosResponse<IPayments> | null> => {
    let response: AxiosResponse<IPayments> | null = null;
    try {
        response = await oamAPI.get('/oam/private/payments', {
            params: {
                accountNumber: encryptedAccountNumber,
            },
        });

        // Enable this fake call when you want to mock BE
        // response = await mockGetPaymentsCall();
    } catch (error: any) {
        TrackJS.track(error);
    }

    return response;
};

export const getPaymentsAsync = createAsyncThunk(
    'account/getPayments',
    async (encryptedAccountNumber: string, { rejectWithValue }) => {
        const response = await getPayments(encryptedAccountNumber);

        if (response?.data) {
            return response?.data;
        }

        return rejectWithValue(response);
    }
);

export const deletePayment = async (request: IDeletePaymentRequest): Promise<AxiosResponse | null> => {
    let response: AxiosResponse | null = null;
    try {
        response = await oamAPI.delete('/oam/private/payments', {
            params: {
                accountNumber: request.encryptedAccountNumber,
                isRecurring: request.payment.paymentIsRecurring,
                paymentConfirmationNumber: request.payment.paymentConfirmationNumber,
            },
        });
    } catch (error: any) {
        TrackJS.track(error);
    }

    return response;
};

export const deletePaymentAsync = createAsyncThunk(
    'account/deletePayment',
    async (request: IDeletePaymentRequest, { rejectWithValue }) => {
        const response = await deletePayment(request);

        if (response?.data) {
            return { payment: request.payment };
        }

        return rejectWithValue(response);
    }
);

export const editPayment = async (request: IEditPaymentRequest): Promise<AxiosResponse | null> => {
    let response: AxiosResponse | null = null;
    const { data, encryptedAccountApplicationNumber, encryptedAccountNumber } = request;
    const { paymentType } = data;

    const paymentIsRecurring = paymentType === EPaymentTypes.RECURRING_PAYMENTS;
    const payload = paymentIsRecurring ? buildRecurringPayload(data) : buildOTPEditPayload(data);

    try {
        response = await oamAPI.put('/oam/private/payments', payload, {
            params: {
                accountApplicationNumber: encryptedAccountApplicationNumber,
                accountNumber: encryptedAccountNumber,
                excludedCodes: [400, 500],
            },
        });

        // Enable fake call for testing
        // response = (await mockSetupNewPayment()) as AxiosResponse;
    } catch (error: any) {
        TrackJS.track(error);
    }

    return response;
};

export const editPaymentAsync = createAsyncThunk(
    'account/editPayment',
    async (request: IEditPaymentRequest, { rejectWithValue }) => {
        const response = await editPayment(request);

        if (response && response?.data) {
            return response?.data;
        }

        return rejectWithValue(response);
    }
);

export const setupNewPayment = async (request: ISetupNewPaymentRequest): Promise<AxiosResponse | null> => {
    let response: AxiosResponse | null = null;
    const { data, encryptedAccountNumber } = request;
    const { paymentType } = data;

    const paymentIsRecurring = paymentType === EPaymentTypes.RECURRING_PAYMENTS;
    const payload = paymentIsRecurring ? buildRecurringPayload(data) : buildOTPPayload(data);

    try {
        response = await oamAPI.post('/oam/private/payments', payload, {
            params: {
                accountNumber: encryptedAccountNumber,
                excludedCodes: [400, 500],
            },
        });

        // Enable fake call for testing
        // response = (await mockSetupNewPayment()) as AxiosResponse;
    } catch (error: any) {
        TrackJS.track(error);
    }

    return response;
};

export const setupNewPaymentAsync = createAsyncThunk(
    'account/setupNewPayment',
    async (request: ISetupNewPaymentRequest, { rejectWithValue }) => {
        const response = await setupNewPayment(request);

        if (response && response?.data) {
            return response?.data;
        }

        return rejectWithValue(response);
    }
);
