import { APP_CONTEXT } from 'config/constants';
import { ELayouts } from 'providers/Layout/types';
import { AppContextEnum } from 'store/redux/modules/types';

export interface IRouteLayout {
    disableStepManager?: boolean;
    layout?: ELayouts;
}

export interface IRouteConfig extends IRouteLayout {
    component: React.ReactElement;
}

export interface IScreenConfigs {
    [key: string]: IRouteConfig;
}

export enum StepNameEnum {
    OFFER_PAGE = 'OFFER_PAGE',
    ADDRESS_PAGE = 'ADDRESS_PAGE',
    CONNECT_TO_FLINKS = 'CONNECT_TO_FLINKS',
    CREDIT_CHECK = 'CREDIT_CHECK',
    CREDIT_CHECK_SOFT = 'CREDIT_CHECK_SOFT',
    PRODUCT_SELECT = 'PRODUCT_SELECT',
    UPLOAD_DOCUMENTS = 'UPLOAD_DOCUMENTS',
    ID_VERIFICATION = 'ID_VERIFICATION',
    BANK_INFORMATION = 'BANK_INFORMATION',
    THANK_YOU = 'THANK_YOU',
    LOGIN = 'LOGIN',
    REGISTRATION = 'REGISTRATION',
    CREDENTIALS = 'CREDENTIALS',
    CANCEL_EMAIL = 'CANCEL_EMAIL',
    PROPERTY = 'PROPERTY',
    INCOME = 'INCOME',
    ACCOUNT = 'ACCOUNT',
    ACCOUNT_OVERVIEW = 'ACCOUNT_OVERVIEW',
    DEMOGRAPHIC = 'DEMOGRAPHIC',
    ACCOUNT_SETUP = 'ACCOUNT_SETUP',
    VERIFY_ACCOUNT = 'VERIFY_ACCOUNT',
}

export const redirectOptions = {
    defaultValue: StepNameEnum.LOGIN,
    whitelist: [StepNameEnum.REGISTRATION],
};

export const isRPOS = (): boolean => APP_CONTEXT === AppContextEnum.RPOS;

export const ScreenUrlPathsEnum = {
    ACCOUNT: isRPOS() ? 'rpos.account.uri' : 'routes.account.uri',
    ACCOUNT_OVERVIEW: isRPOS() ? 'rpos.account-overview.uri' : 'routes.account-overview.uri',
    ACCOUNT_SETUP: isRPOS() ? 'rpos.account-setup.uri' : 'routes.account-setup.uri',
    ADDRESS_PAGE: isRPOS() ? 'rpos.update-address.uri' : 'routes.update-address.uri',
    BANK_INFORMATION: isRPOS() ? 'rpos.bank-details.uri' : 'routes.bank-details.uri',
    CANCEL_EMAIL: isRPOS() ? 'rpos.cancel-email-update.uri' : 'routes.cancel-email-update.uri',
    CONNECT_TO_FLINKS: isRPOS() ? 'rpos.connect-to-flinks.uri' : 'routes.connect-to-flinks.uri',
    CREDENTIALS: isRPOS() ? 'rpos.credentials.uri' : 'routes.credentials.uri',
    CREDIT_CHECK: isRPOS() ? 'rpos.credit-consent-hard.uri' : 'routes.credit-consent-hard.uri',
    CREDIT_CHECK_SOFT: isRPOS() ? 'rpos.credit-consent-soft.uri' : 'routes.credit-consent-soft.uri',
    DEMOGRAPHIC: isRPOS() ? 'rpos.demographic.uri' : 'routes.demographic.uri',
    ID_VERIFICATION: isRPOS() ? 'rpos.id-verification.uri' : 'routes.id-verification.uri',
    INCOME: isRPOS() ? 'rpos.update-income.uri' : 'routes.update-income.uri',
    LOGIN: isRPOS() ? 'rpos.login.uri' : 'routes.login.uri',
    LOGOUT: isRPOS() ? 'rpos.logout.uri' : 'routes.logout.uri',
    OFFER_PAGE: isRPOS() ? 'rpos.offers.uri' : 'routes.offers.uri',
    PASSWORD: 'routes.profile.password.uri',
    PRODUCT_SELECT: isRPOS() ? 'rpos.product-insurance.uri' : 'routes.product-insurance.uri',
    PROFILE: 'routes.profile.uri',
    PROPERTY: isRPOS() ? 'rpos.property.uri' : 'routes.property.uri',
    REGISTRATION: isRPOS() ? 'rpos.registration.uri' : 'routes.registration.uri',
    THANK_YOU: isRPOS() ? 'rpos.thank-you.uri' : 'routes.thank-you.uri',
    UPDATE_PROFILE: 'routes.profile.update.uri',
    UPLOAD_DOCUMENTS: isRPOS() ? 'rpos.document-verification.uri' : 'routes.document-verification.uri',
    VERIFY_ACCOUNT: isRPOS() ? 'rpos.verify-account.uri' : 'routes.verify-account.uri',
} as const;

export type ScreenUrlPathsEnumType = (typeof ScreenUrlPathsEnum)[keyof typeof ScreenUrlPathsEnum];
