import TrackJS from '@fairstone-frontend/utils/core/logs/trackjs';
import { Auth } from 'aws-amplify';
import jwt_decode from 'jwt-decode';

import { IAuthHeader, ICredentialTokens, IRoamAuthHeader } from 'types/generic';
export const getCredentialTokens = async (): Promise<ICredentialTokens> => {
    let session = null;
    let accessToken = '';
    let idToken = '';

    try {
        session = await Auth.currentSession();
        accessToken = session.getAccessToken().getJwtToken();
        idToken = session.getIdToken().getJwtToken();
    } catch (e: any) {
        e !== 'No current user' && TrackJS.track(e);
    }

    return { accessToken, idToken };
};

export const getAuthTokens = async (): Promise<IAuthHeader> => {
    const { accessToken, idToken } = await getCredentialTokens();

    return {
        Authorization: `Bearer ${accessToken}`,
        'x-fairstone-identity': idToken,
    };
};

export const getRoamAuthTokens = async (): Promise<IRoamAuthHeader> => {
    const { accessToken, idToken } = await getCredentialTokens();

    return {
        authorization: `Bearer ${accessToken}`,
        'x-oam-identity': idToken,
    };
};

export const getCognitoSub = async (): Promise<string> => {
    let cognitoSub = '';

    try {
        const user = await Auth.currentUserInfo();
        const { sub } = user.attributes;
        cognitoSub = sub;
    } catch (e: any) {
        TrackJS.track(e);
    }

    return cognitoSub;
};

interface jwtToken {
    'custom:account_id': string;
    'custom:ucp_id': string;
    [key: string]: string | number | boolean;
}

let accountNumber: string | null = null;

export const getAccountNumber = async (): Promise<string | null> => {
    if (accountNumber) return accountNumber;

    const { idToken } = await getCredentialTokens();

    if (idToken) {
        try {
            const decoded: jwtToken = jwt_decode(idToken);
            accountNumber = decoded['custom:account_id'];
        } catch (error: any) {
            TrackJS.track(error);
            return null;
        }
    }

    return accountNumber;
};

let ucpId: string | null = null;

export const getUcpId = async (): Promise<string | null> => {
    if (ucpId) return ucpId;

    const { idToken } = await getCredentialTokens();
    try {
        const decoded: jwtToken = jwt_decode(idToken);
        ucpId = decoded['custom:ucp_id'];
    } catch (error: any) {
        TrackJS.track(error);
    }

    return ucpId;
};
