import React, { useCallback, useEffect, useState } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { Button } from '@fairstone/ui/core/components/Button';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@fairstone/ui/core/components/Dialog';
import { AccessTime as AccessTimeIcon } from '@fairstone/ui/core/components/icons/AccessTime';
import { t } from '@fairstone/ui/core/utils/translate';

import { SESSION_TIMEOUT_LOGOUT, SESSION_TIMEOUT_POPUP } from 'config/constants';
import { onSignedOut } from 'store/redux/actions';
import { useAppDispatch, useAppSelector } from 'store/redux/hooks';
import { appState } from 'store/redux/modules/application';

import styles from './SessionManager.module.scss';
let logoutTimeout: NodeJS.Timeout | null = null;

export const SessionManager: React.FC<React.PropsWithChildren> = ({ children }) => {
    const dispatch = useAppDispatch();
    const { isAuthenticated } = useAppSelector(appState);

    const [isOpen, setIsOpen] = useState(false);

    const handleOnIdle = () => {
        setIsOpen(true);

        logoutTimeout = setTimeout(
            () => {
                dispatch(onSignedOut());
            },
            1000 * 60 * SESSION_TIMEOUT_LOGOUT
        );
    };

    const resetTimeout = useCallback(() => {
        if (logoutTimeout) {
            clearTimeout(logoutTimeout);
        }
    }, []);

    const { pause, reset, start } = useIdleTimer({
        debounce: 500,
        onIdle: handleOnIdle,
        stopOnIdle: true,
        timeout: 1000 * 60 * SESSION_TIMEOUT_POPUP,
    });

    const handleClose = useCallback(() => {
        resetTimeout();
        reset();
        setIsOpen(false);
    }, [reset, resetTimeout]);

    useEffect(() => {
        if (isAuthenticated) {
            start();
        } else pause();
    }, [isAuthenticated, pause, start]);

    return (
        <>
            <Dialog
                className={styles.sessionDialog}
                data-testid="session-manager"
                disableEscapeKeyDown
                onClose={handleClose}
                open={isOpen}
            >
                <DialogTitle>
                    <div className={styles.titleContainer}>
                        <div>
                            <AccessTimeIcon className={styles.icon} color="error" />
                        </div>
                        {t('session.title')}
                    </div>
                </DialogTitle>
                <DialogContent className={styles.center}>{t('session.message')}</DialogContent>
                <DialogActions>
                    <Button appearance="contained" border className={styles.centerBtn} onClick={handleClose}>
                        {t('session.action')}
                    </Button>
                </DialogActions>
            </Dialog>
            {children}
        </>
    );
};
