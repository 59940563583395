import TrackJS from '@fairstone-frontend/utils/core/logs/trackjs';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';

import { restAPI } from 'services/api';

import { setApplicationError } from '../application';

import {
    IProductServicePayload,
    IProductServiceResponse,
    IProductServiceUpdatePayload,
    IProductServiceUpdateResponse,
    IRPOSOptionalProductRequest,
    IRPOSOptionalProductResponse,
    IRPOSProductOfferRequest,
    IRPOSProductOfferResponse,
    IRPOSProductUpdateRequest,
    IRPOSUpdateOptionalProductRequest,
} from './types';
export const getProductInsurance = async (
    payload: IProductServicePayload
): Promise<AxiosResponse<IProductServiceResponse> | null> => {
    let response: AxiosResponse<IProductServiceResponse> | null = null;
    try {
        response = await restAPI.get('/user/product-offer', {
            params: payload,
        });
    } catch (error: any) {
        TrackJS.track(error);
    }

    return response;
};

export const updateProductInsurance = async (
    payload: IProductServiceUpdatePayload
): Promise<AxiosResponse<IProductServiceUpdateResponse> | null> => {
    let response: AxiosResponse<IProductServiceUpdateResponse> | null = null;
    try {
        response = await restAPI.post('/user/product-offer', payload);
    } catch (error: any) {
        TrackJS.track(error);
    }

    return response;
};

export const getProductInsuranceAsync = createAsyncThunk(
    'productInsurance/fetchProductInsurance',
    async (payload: IProductServicePayload, { rejectWithValue }) => {
        const response = await getProductInsurance(payload);

        if (response && response?.data) {
            return response?.data;
        }

        return rejectWithValue(response);
    }
);

export const updateProductTermsAsync = createAsyncThunk(
    'productInsurance/updateProductTerms',
    async (payload: IProductServiceUpdatePayload, { dispatch, rejectWithValue }) => {
        const response = await updateProductInsurance(payload);

        if (response && response?.data) {
            return response?.data;
        }

        dispatch(setApplicationError(true));
        return rejectWithValue(response);
    }
);

const getRPOSProductOffer = async (
    payload: IRPOSProductOfferRequest
): Promise<AxiosResponse<IRPOSProductOfferResponse> | null> => {
    let response: AxiosResponse<IRPOSProductOfferResponse> | null = null;
    try {
        response = await restAPI.get('/offer/product', {
            params: payload,
        });
    } catch (error: any) {
        TrackJS.track(error);
    }

    return response;
};

const getRPOSOptionalProduct = async (
    payload: IRPOSOptionalProductRequest
): Promise<AxiosResponse<IRPOSOptionalProductResponse> | null> => {
    let response: AxiosResponse<IRPOSOptionalProductResponse> | null = null;
    try {
        response = await restAPI.get('/offer/product/optionals', {
            params: payload,
        });
    } catch (error: any) {
        TrackJS.track(error);
    }

    return response;
};

const updateRPOSOptionalProduct = async (
    payload: IRPOSUpdateOptionalProductRequest
): Promise<AxiosResponse<IRPOSOptionalProductResponse> | null> => {
    let response: AxiosResponse<IRPOSOptionalProductResponse> | null = null;
    try {
        // PATCH
        const finalProducts = payload.optionalProduct.map((product) => {
            if (product.productType === 'DIS') {
                return { ...product, productType: 'DISABILITY' };
            }

            return product;
        });
        payload.optionalProduct = finalProducts;
        //END PATCH
        response = await restAPI.post('/offer/product/optionals', payload);
    } catch (error: any) {
        TrackJS.track(error);
    }

    return response;
};

export const getRPOSProductOfferAsync = createAsyncThunk(
    'productInsurance/getRPOSProductOffer',
    async (payload: IRPOSProductOfferRequest, { rejectWithValue }) => {
        const response = await getRPOSProductOffer(payload);

        if (response && response?.data) {
            return response.data;
        }

        return rejectWithValue(response);
    }
);

export const getRPOSOptionalProductAsync = createAsyncThunk(
    'productInsurance/getRPOSOptionalProduct',
    async (payload: IRPOSOptionalProductRequest, { rejectWithValue }) => {
        const response = await getRPOSOptionalProduct(payload);
        if (response && response?.data) {
            return response.data;
        }

        return rejectWithValue(response);
    }
);

export const updateRPOSOptionalProductAsync = createAsyncThunk(
    'productInsurance/updateRPOSOptionalProduct',
    async (payload: IRPOSUpdateOptionalProductRequest, { rejectWithValue }) => {
        const response = await updateRPOSOptionalProduct(payload);

        if (response && response?.data) {
            return response.data;
        }

        return rejectWithValue(response);
    }
);

const updateRPOSProductOffer = async (
    payload: IRPOSProductUpdateRequest
): Promise<AxiosResponse<IProductServiceUpdateResponse> | null> => {
    let response: AxiosResponse<IProductServiceUpdateResponse> | null = null;
    try {
        response = await restAPI.post('/offer/product', payload);
    } catch (error: any) {
        TrackJS.track(error);
    }

    return response;
};

export const updateRPOSProductOfferAsync = createAsyncThunk(
    'productInsurance/updateRPOSProductOffer',
    async (payload: IRPOSProductUpdateRequest, { rejectWithValue }) => {
        const response = await updateRPOSProductOffer(payload);

        if (response && response?.data) {
            return response.data;
        }

        return rejectWithValue(response);
    }
);
