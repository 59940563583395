export interface IGetUrlPayload extends IApplicationRequest {
    extension: string;
    locale: string;
    type: string;
}

export interface IApplicationRequest {
    applicationNumber: string;
    branchNumber: string;
}

export enum DocumentTypeEnum {
    ALIMONY = 'alimony',
    INCOME_OTHER = 'income_other',
    RENTAL = 'rental',
    ID_OTHER = 'id_other',
    RENTER = 'renter',
    HOUSING_OTHER = 'housing_other',
    CHILDTAX = 'childtax',
    DISABILITY = 'disability',
    PENSION = 'pension',
    RETIREMENT = 'retired',
    UNEMPLOYED = 'unemployed',
    EMPLOYED = 'employed',
    WELFARE = 'welfare',
}

export enum DocumentCategoryEnum {
    ADDRESS = 'ADDRESS',
    INCOME = 'INCOME',
    HOUSING = 'HOUSING',
}

export interface IDocumentSection {
    documents: IDocument[];
    type: DocumentTypeEnum;
    error: boolean | null;
}

export interface IDocument {
    file: File;
    uploaded: boolean;
}

export interface IUploadRequest {
    application: IApplicationRequest;
    sections: IDocumentSection[];
}
