import { RootState } from 'types/store';

import { applicationSlice } from './reducers';
import { IApplicationState } from './types';
export {
    createNonGloApplicationAsync,
    createRposApplicationAsync,
    getLatestStepAsync,
    updateLatestStepAsync,
} from './actions';

export const appState = (state: RootState): IApplicationState => state.application;
export const {
    setAdplacementId,
    setAlreadySubmitted,
    setApplicationError,
    setApplicationFlow,
    setApplicationNumber,
    setBranchNumber,
    setChannelCode,
    setConnectedToFlinks,
    setFeatureFlags,
    setHasRanOmniContext,
    setIsAuthenticated,
    setLastStepVisited,
    setLatestStep,
} = applicationSlice.actions;
export default applicationSlice.reducer;
