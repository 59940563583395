import { TLangTranslations, translations } from '@fairstone/ui/core/providers/Intl';
import { translate } from '@fairstone/ui/core/providers/Intl/locales/translate';
import { getLocale } from '@fairstone/ui/core/store/cache/locales';

import { flowMap } from 'routes/FlowManager';
import { ScreenUrlPathsEnum, ScreenUrlPathsEnumType, StepNameEnum } from 'routes/types';
import { IAccount } from 'types/account';

export interface IRedirectUrl {
    path: Record<string, string>;
}

export const setRedirectUrl = ({ path }: IRedirectUrl): void => {
    sessionStorage.setItem('redirect-url', JSON.stringify(path));
};

export const getRedirectUrl = (clear?: boolean): Record<string, string> | null => {
    const url = sessionStorage.getItem('redirect-url');

    if (clear) clearRedirectUrl();

    return url ? JSON.parse(url) : null;
};

export const clearRedirectUrl = (): void => {
    sessionStorage.removeItem('redirect-url');
};

export const searchStepName = (path: string): StepNameEnum =>
    Object.keys(ScreenUrlPathsEnum).find(
        (k) => translate(ScreenUrlPathsEnum[k as StepNameEnum]) === path
    ) as StepNameEnum;

export const findStepName = (path: string | undefined): StepNameEnum => {
    if (path) {
        return searchStepName(path);
    }

    return StepNameEnum.OFFER_PAGE;
};

export const findStepNameByURL = (url: ScreenUrlPathsEnumType): StepNameEnum =>
    Object.keys(ScreenUrlPathsEnum).find((k) => ScreenUrlPathsEnum[k as StepNameEnum] === url) as StepNameEnum;

export const isAllowedToRoute = (page: StepNameEnum, latestPage: StepNameEnum): boolean => {
    if (page === latestPage) {
        return true;
    }

    if (latestPage === StepNameEnum.THANK_YOU) return false;

    if (page === StepNameEnum.ADDRESS_PAGE) return true;

    return isFurthestStep(page, latestPage);
};

export const isFurthestStep = (page: StepNameEnum, latestPage: StepNameEnum): boolean => {
    if (flowMap) {
        const pageStep = flowMap?.get(page);
        const latestStep = flowMap?.get(latestPage);
        if (latestStep && pageStep) return pageStep <= latestStep;
    }

    return false;
};

export const getRoutePercent = (stepName: StepNameEnum): number => {
    if (flowMap)
        return (
            (100 / (flowMap.size - 1)) * (stepName !== StepNameEnum.OFFER_PAGE ? (flowMap.get(stepName) || 0) - 1 : 0)
        );
    else return 0;
};

export const getPaths = (key: string): string[] =>
    Object.keys(translations).map((lang) => translations[lang as TLangTranslations]![key]);

export const getPathForCurrentLocale = (key: string): string => {
    const paths = Object.keys(translations)
        .filter((lang) => lang === getLocale())
        .map((lang) => translations[lang as TLangTranslations]![key]);

    return paths.length > 0 ? paths[0] : '';
};

export const getFirstAccountNumber = (accounts: IAccount[]): string => {
    if (!accounts.length) return '';

    return accounts[0].accountNumber || '';
};
