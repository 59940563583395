import React from 'react';
import { FormatNumberOptions } from 'react-intl';
import { Link } from 'react-router-dom';
import { t, translate } from '@fairstone/ui/core/utils/translate';
import { isEmpty } from 'lodash';

import { ErrorTypesByException } from 'pages/Login/types';

import { ELangCodes } from './constants';

export const formatNumberOptions: FormatNumberOptions = {
    currency: 'CAD',
    currencyDisplay: 'narrowSymbol',
    style: 'currency',
};

export const formatNumberWithoutDecimal: FormatNumberOptions = {
    currency: 'CAD',
    currencyDisplay: 'narrowSymbol',
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
    style: 'currency',
};

export const formatAmount = (initialValue: number, value: string, locale: string): number => {
    const cleanInput = value.replace(/\D+/g, '');
    let currentAmount = 0;

    if (!isEmpty(cleanInput)) {
        currentAmount = parseInt(`${cleanInput}`);

        //Delete from the back, french only
        if (initialValue === currentAmount && locale === ELangCodes.FRENCH) {
            const newAmount = String(currentAmount).substr(0, String(currentAmount).length - 1) || 0;
            currentAmount = parseInt(`${newAmount}`);
        }
    }

    return currentAmount;
};

export const formatDate = (date: Date): string =>
    date.getFullYear() + '-' + ('0' + (date.getMonth() + 1)).slice(-2) + '-' + ('0' + date.getDate()).slice(-2);

export const formatPhoneNumberOnly = (value: string): string => {
    let phoneNumber = '';
    const cleanInput = value.replace(/[^0-9]+/g, '');

    if (cleanInput.length > 3 && cleanInput.length < 7) {
        phoneNumber = cleanInput.slice(0, 3) + '-' + cleanInput.slice(3, 6);
    } else if (cleanInput.length > 6) {
        phoneNumber = cleanInput.slice(0, 3) + '-' + cleanInput.slice(3, 6) + '-' + cleanInput.slice(6, 10);
    } else {
        phoneNumber = cleanInput;
    }
    return phoneNumber;
};

export const formatOAMPhoneNumberOnly = (value: string): string => {
    let phoneNumber = '';
    const cleanInput = value.replace(/[^0-9]+/g, '');

    if (cleanInput.length > 3 && cleanInput.length < 7) {
        phoneNumber = cleanInput.slice(0, 3) + '-' + cleanInput.slice(3, 6);
    } else if (cleanInput.length > 6) {
        phoneNumber = '(' + cleanInput.slice(0, 3) + ') ' + cleanInput.slice(3, 6) + '-' + cleanInput.slice(6, 10);
    } else {
        phoneNumber = cleanInput;
    }
    return phoneNumber;
};

export const formatCharacterOnly = (value: string): string => {
    const cleanInput = value.replace(/[^a-zA-Z]+/g, '');
    return cleanInput;
};

export const formatAccountNumber = (value: string): string => {
    const maskedChars = 'X'.repeat(value.length - 3);
    return maskedChars + value.slice(-3);
};

export const stripFormatting = (value: string | number): number => {
    const valueStr = value.toString();
    if (!valueStr.length) return 0;

    const strippedStr = valueStr.replace(/[^0-9a-zA-Z.-]+/g, '');
    const strippedValue = parseFloat(strippedStr);
    return isNaN(strippedValue) ? NaN : strippedValue;
};

export const getDateFormat = (locale: string): string =>
    locale === ELangCodes.ENGLISH ? 'MMMM d, yyyy' : 'd MMMM yyyy';

export const compileErrorMessage = (message: string, linkStyle: string): React.ReactElement => {
    if (isEmpty(message)) return t(message);

    const langKeys = message.split('.');
    const errorKey = langKeys[langKeys.length - 1];

    switch (errorKey) {
        case ErrorTypesByException.IsBlocked:
        case ErrorTypesByException.NoCustomerInformationFound:
            return t(`${message}.label`, {
                contactLink: (
                    <Link
                        className={linkStyle}
                        data-testid="error-link"
                        target="_blank"
                        to={`tel:${translate(`${message}.contact-link`)}`}
                    >
                        {t(`${message}.contact-label`)}
                    </Link>
                ),
            });
        default:
            return t(message);
    }
};
