import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ON_RESET_EVENT } from 'store/redux/actions';
import { IAccount, IAccountSummary } from 'types/account';
import { ITransactionHistory } from 'types/transactionHistory';

import { getAccountSummaryAsync, getCustomerAccountsAsync, getTransactionHistoryAsync } from './actions';
import { IAccountState } from './types';

export const initialState: IAccountState = {
    accountOverview: {
        accountSummary: {} as IAccountSummary,
        transactionHistory: {} as ITransactionHistory,
    },
    accounts: [],
    encryptedAccountApplicationNumber: '',
    isFetched: false,
    isLoading: {
        accounts: true,
        accountSummary: true,
        transactionHistory: true,
    },
    name: '',
    segments: [],
};

export const accountSlice = createSlice({
    extraReducers: (builder: any) => {
        /* getCustomerAccountsAsync */
        builder.addCase(getCustomerAccountsAsync.pending, (state: IAccountState) => {
            state.isLoading.accounts = true;
        });

        builder.addCase(getCustomerAccountsAsync.fulfilled, (state: IAccountState, action: any) => {
            state.isLoading.accounts = false;

            if (action.payload) {
                const { accounts = [], name = '' } = action.payload;

                state.accounts = accounts;
                state.name = name;
                state.isFetched = true;

                if (accounts.length > 0) {
                    state.encryptedAccountApplicationNumber = accounts[0].encryptedAccountApplicationNumber;
                }
            }
        });

        builder.addCase(getCustomerAccountsAsync.rejected, (state: IAccountState) => {
            state.isLoading.accounts = false;
        });

        /* getAccountSummaryAsync */
        builder.addCase(getAccountSummaryAsync.pending, (state: IAccountState) => {
            state.isLoading.accountSummary = true;
            state.accountOverview.accountSummary = {} as IAccountSummary;
        });

        builder.addCase(getAccountSummaryAsync.fulfilled, (state: IAccountState, action: any) => {
            if (action.payload) {
                state.accountOverview.accountSummary = action.payload;
            }

            state.isLoading.accountSummary = false;
        });

        builder.addCase(getAccountSummaryAsync.rejected, (state: IAccountState) => {
            state.isLoading.accountSummary = false;
        });

        /* getTransactionHistoryAsync */
        builder.addCase(getTransactionHistoryAsync.pending, (state: IAccountState) => {
            state.isLoading.transactionHistory = true;
            state.accountOverview.transactionHistory = {} as ITransactionHistory;
        });

        builder.addCase(getTransactionHistoryAsync.fulfilled, (state: IAccountState, action: any) => {
            state.isLoading.transactionHistory = false;

            if (action.payload) {
                state.accountOverview.transactionHistory = action.payload;
            }
        });

        builder.addCase(getTransactionHistoryAsync.rejected, (state: IAccountState) => {
            state.isLoading.transactionHistory = false;
        });

        /* Other */
        builder.addCase(ON_RESET_EVENT, () => initialState);
    },
    initialState,
    name: 'address',
    reducers: {
        setAccounts(state, action: PayloadAction<IAccount[]>) {
            state.accounts = action.payload;
        },
        setEncryptedAccountApplicationNumber(state, action: PayloadAction<string>) {
            state.encryptedAccountApplicationNumber = action.payload;
        },
        setName(state, action: PayloadAction<string>) {
            state.name = action.payload;
        },
    },
});
