import { ISummary } from './account';

export interface IPayments {
    payments: IPaymentDetails[];
}

export interface IPaymentDetails {
    paymentAmount?: number;
    paymentId?: string;
    paymentIsRecurring?: boolean;
    paymentFrequency?: string;
    paymentSourceName?: string;
    paymentChannelCode?: string;
    paymentConfirmationNumber?: string;
    paymentDate?: string;
    nextPaymentScheduleDate?: string;
    paymentSourceId?: string;
    paymentIsPending?: boolean;
    paymentAdditionalAmount?: number;
    newPaymentDebitDetails?: IPaymentDebitDetails;
}

export interface IPaymentDebitDetails {
    paymentSourceName?: string;
    transitNumber?: string;
    institutionNumber?: string;
    accountNumber?: string;
    savePaymentSource: boolean;
}

export interface IBankInformation {
    accountNumber: string;
    institutionNumber: string;
    nickname: string;
    transitNumber: string;
}

export interface IOTPPayload {
    paymentDate: string;
    paymentAmount: number;
    additionalAmount: number;
    paymentSourceId: string;
    paymentIsRecurring: boolean;
    paymentFrequency: string;
    newPaymentDebitDetails: IPaymentDebitDetails;
    isPayoffPayment: boolean;
    paymentId?: string;
}

export interface IRecurringPayload {
    additionalAmount: number;
    isPayoffPayment: boolean;
    newPaymentDebitDetails: IPaymentDebitDetails;
    paymentAmount: number;
    paymentDate: string;
    paymentFrequency: string;
    paymentIsRecurring: boolean;
    paymentSourceId: string;
}

export interface IPaymentDateLimits {
    min: Date;
    max: Date;
}

export interface IPaymentSourceItem {
    key: EPaymentSources | EPaymentFrequency;
    value: string;
}

export interface IPaymentInfoSelections {
    paymentSources: IPaymentSourceItem[];
    range: IPaymentDateLimits;
    shouldDisableDate?: (date: Date) => boolean;
}

export interface IReviewItem {
    data: string;
    title: string;
}
export interface IPaymentViewDetails {
    showOther: boolean;
    fieldName: EDynamicPaymentOptions;
    fieldValue: EPaymentSources | EPaymentFrequency | string | number | boolean;
}

export interface IPaymentRules {
    isPaymentAllowed: boolean;
    isOTPBlocked: boolean;
    isRecurringBlocked: boolean;
    hasRecurringPayments: boolean;
}

export interface IPaymentDisplayDetails {
    showForm: boolean;
    errorMessage: string;
}

export interface IEditPaymentDetails {
    isValid: boolean;
    errorMessage: string;
}

export enum EDynamicPaymentOptions {
    PAYMENT_AMOUNT = 'paymentAmount',
    PAYMENT_TYPE = 'paymentType',
    PAYMENT_SOURCE = 'paymentSource',
    PAYMENT_DATE = 'paymentDate',
    ADDITIONAL_PAYMENT = 'additionalAmount',
}

export enum EPaymentFrequency {
    BIWEEKLY = 'biweekly',
    SEMIMONTHLY = 'semi-monthly',
    MONTHLY = 'monthly',
    ONCE = 'once',
}

export enum EWeekDays {
    SUNDAY = 0,
    MONDAY = 1,
    TUESDAY = 2,
    WEDNESDAY = 3,
    THURSDAY = 4,
    FRIDAY = 5,
    SATURDAY = 6,
}

export enum EPaymentTypes {
    OTP = '1',
    RECURRING_PAYMENTS = '2',
}

export enum EPaymentSources {
    OTHER = 'otherAmount',
    CURRENT = 'currentPaymentDue',
    PAST_DUE = 'amountPastDue',
    MONTHLY = 'monthlyPaymentAmount',
}

export type TValidationDetails = Pick<
    ISummary,
    | 'monthlyPaymentAmount'
    | 'currentBalance'
    | 'payOffAmount'
    | 'realEstateProduct'
    | 'payOffAttemptFlag'
    | 'paymentLimit'
>;

export type TPaymentInformation = IBankInformation & {
    additionalAmount: string;
    paymentDate: Date;
    paymentType: string;
    paymentAmount: string;
    paymentSource: string;
    paymentSourceId: string;
    paymentId: string;
    accountDetails?: TValidationDetails;
};

export interface ISetupFuturePaymentResponse {
    setupFuturePaymentReturn: {
        setupFuturePaymentRequestReturn: {
            statusCode: string;
            statusMessage: string;
            paymentReceiptId?: string;
            errorCode?: string;
        };
    };
}
