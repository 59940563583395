import TrackJS from '@fairstone-frontend/utils/core/logs/trackjs';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';

import { restAPI } from 'services/api';

import { IDemographicRequest, IDemographicResponse } from './types';

const getDemographicInfo = async (request: IDemographicRequest) => {
    let response: AxiosResponse<IDemographicResponse> | null = null;
    try {
        response = await restAPI.get('/user/demographics', {
            params: request,
        });
    } catch (error: any) {
        TrackJS.track(error);
    }

    return response;
};

export const getDemographicInfoAsync = createAsyncThunk(
    'demographic/fetchPersonalInfo',
    async (request: IDemographicRequest, { rejectWithValue }) => {
        const response = await getDemographicInfo(request);

        if (response && response?.data) {
            return response?.data;
        }

        return rejectWithValue(response);
    }
);
