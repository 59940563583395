import TrackJS from '@fairstone-frontend/utils/core/logs/trackjs';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';

import { restAPI } from 'services/api';

import { setApplicationError, setApplicationNumber } from '../application';

import {
    ICreditConsentRequestPayload,
    ICreditConsentResponsePayload,
    IRPOSCreditCheckRequest,
    IRPOSCreditCheckResponse,
    ISoftCreditConsentRequestPayload,
    ISoftCreditConsentResponsePayload,
} from './types';

export const postCreditConsent = async (
    payload: ICreditConsentRequestPayload
): Promise<AxiosResponse<ICreditConsentResponsePayload> | null> => {
    let response: AxiosResponse<ICreditConsentResponsePayload> | null = null;
    try {
        response = await restAPI.post('/user/run-credit-check', payload);
    } catch (error: any) {
        TrackJS.track(error);
    }

    return response;
};

export const postSoftCreditConsent = async (
    payload: ISoftCreditConsentRequestPayload
): Promise<AxiosResponse<ISoftCreditConsentResponsePayload> | null> => {
    let response: AxiosResponse<ISoftCreditConsentResponsePayload> | null = null;
    try {
        response = await restAPI.post('/user/run-credit-check-soft', payload);
    } catch (error: any) {
        TrackJS.track(error);
    }

    return response;
};

export const postCreditConsentAsync = createAsyncThunk(
    'credit-consent/postCreditConsent',
    async (payload: ICreditConsentRequestPayload, { dispatch, rejectWithValue }) => {
        const response = await postCreditConsent(payload);

        if (response && response?.data) {
            if (response.data?.applicationNumber) {
                dispatch(setApplicationNumber(response.data?.applicationNumber));
            }
            return response?.data;
        }

        dispatch(setApplicationError(true));
        return rejectWithValue(false);
    }
);

export const postSoftCreditConsentAsync = createAsyncThunk(
    'credit-consent/postSoftCreditConsent',
    async (payload: ISoftCreditConsentRequestPayload, { dispatch, rejectWithValue }) => {
        const response = await postSoftCreditConsent(payload);

        if (response && response?.data) {
            return response?.data;
        }

        dispatch(setApplicationError(true));
        return rejectWithValue(response);
    }
);

const performRPOSCreditCheck = async (payload: IRPOSCreditCheckRequest) => {
    let response: AxiosResponse<IRPOSCreditCheckResponse> | null = null;
    try {
        response = await restAPI.post('/offer/perform-credit-check', payload);
    } catch (error: any) {
        TrackJS.track(error);
    }

    return response;
};

export const performRPOSCreditCheckAsync = createAsyncThunk(
    'credit-consent/performRPOSCreditCheck',
    async (payload: IRPOSCreditCheckRequest, { rejectWithValue }) => {
        const response = await performRPOSCreditCheck(payload);

        if (response && response?.data) {
            return response?.data;
        }

        return rejectWithValue(response);
    }
);
