import { RootState } from 'types/store';

import { customerSlice } from './reducers';
import { ICustomerState } from './types';

export const customerState = (state: RootState): ICustomerState => state.customer;

export {
    resendCodeAsync,
    retrieveBannerDetailsAsync,
    retrieveUsernameAsync,
    verifyCodeAsync,
    verifyCustomerAsync,
} from './actions';
export { initialState } from './reducers';

export const {
    clearCustomerError,
    clearUsername,
    clearVerificationKey,
    setAccountNumber,
    setCustomerInformation,
    setCustomerUsername,
    setUcpId,
} = customerSlice.actions;

export default customerSlice.reducer;
