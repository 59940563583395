import { RootState } from 'types/store';

import { IProductInsuranceState, productInsuranceSlice } from './reducers';

export const selectProductInsurance = (state: RootState): IProductInsuranceState => state.productInsurance;
export {
    getProductInsuranceAsync,
    getRPOSProductOfferAsync,
    updateProductTermsAsync,
    updateRPOSProductOfferAsync,
} from './actions';
export const { setSelectedTerm } = productInsuranceSlice.actions;
export default productInsuranceSlice.reducer;
