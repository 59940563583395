import moment from 'moment-timezone';

import { ISummary } from 'types/account';
import {
    EPaymentFrequency,
    EPaymentSources,
    EPaymentTypes,
    IEditPaymentDetails,
    IPaymentDetails,
    TPaymentInformation,
} from 'types/payments';

import { isPaymentAllowedInLS } from './paymentDisplayValidationUtils';

export const canEditPayment = (payment: IPaymentDetails): IEditPaymentDetails => {
    const { nextPaymentScheduleDate, paymentDate, paymentFrequency, paymentIsRecurring } = payment;

    const editDetails: IEditPaymentDetails = {
        errorMessage: '',
        isValid: true,
    };
    const isAllowed = isPaymentAllowedInLS();

    if (!isAllowed) {
        editDetails.errorMessage = 'pages.account-overview.fragments.payments.paymentDialog.paymentNotAllowed';
        editDetails.isValid = false;
    } else {
        const today = moment.utc();
        const nextScheduledDate = moment.utc(nextPaymentScheduleDate);
        const payDate = moment.utc(paymentDate);

        if (paymentIsRecurring) {
            const diff = nextScheduledDate.diff(payDate, 'days');
            if (
                (paymentFrequency === EPaymentFrequency.BIWEEKLY ||
                    paymentFrequency === EPaymentFrequency.SEMIMONTHLY) &&
                !diff
            ) {
                editDetails.errorMessage =
                    'pages.account-overview.fragments.payments.paymentDialog.cannotEditScheduledPayment';
                editDetails.isValid = false;
            }
        } else {
            if (payDate.isSame(today, 'day') || payDate.isBefore(today, 'day')) {
                editDetails.errorMessage = 'pages.account-overview.fragments.payments.dialog.error';
                editDetails.isValid = false;
            }
        }
    }

    return editDetails;
};

export const getEditType = (payment: IPaymentDetails, summary: ISummary): EPaymentSources => {
    const { amountPastDue, monthlyPaymentAmount, totalDueAmount } = summary;
    const { paymentAmount = 0, paymentFrequency } = payment;
    const totalAmount = Number(paymentAmount.toFixed(2));
    const paymentDue = Number(totalDueAmount.toFixed(2));
    const pastDueAmount = Number(amountPastDue.toFixed(2));
    const monthlyAmount = Number(monthlyPaymentAmount.toFixed(2));

    if (totalAmount && paymentFrequency?.toLowerCase() === EPaymentFrequency.ONCE) {
        const currentAmount = Number((totalDueAmount + amountPastDue).toFixed(2));
        if (totalAmount === currentAmount) return EPaymentSources.CURRENT;
        if (totalAmount === pastDueAmount) return EPaymentSources.PAST_DUE;
        if (totalAmount === monthlyAmount) return EPaymentSources.MONTHLY;
    } else {
        if (paymentDue) return EPaymentSources.CURRENT;
        if (pastDueAmount) return EPaymentSources.PAST_DUE;
        if (monthlyAmount) return EPaymentSources.MONTHLY;
    }

    return EPaymentSources.OTHER;
};

export const prepareFormValues = (payment: IPaymentDetails, summary: ISummary): TPaymentInformation => {
    const {
        newPaymentDebitDetails: {
            accountNumber: paymentAcctNum = '',
            institutionNumber = '',
            paymentSourceName = '',
            transitNumber = '',
        } = {},
        paymentAdditionalAmount,
        paymentAmount = 0,
        paymentDate,
        paymentId = '',
        paymentIsRecurring,
        paymentSourceId = '',
    } = payment;

    return {
        accountNumber: paymentAcctNum,
        additionalAmount: paymentAdditionalAmount?.toString() || '',
        institutionNumber,
        nickname: paymentSourceName,
        paymentAmount: (paymentAmount - (paymentAdditionalAmount || 0)).toString(),
        paymentDate: moment(paymentDate).toDate(),
        paymentId,
        paymentSource: getEditType(payment, summary),
        paymentSourceId,
        paymentType: paymentIsRecurring ? EPaymentTypes.RECURRING_PAYMENTS : EPaymentTypes.OTP,
        transitNumber,
    };
};
