import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { isEmpty, startCase } from 'lodash';

import { ON_RESET_EVENT } from 'store/redux/actions';

import { getAddressAsync, getResidentialInfoAsync, updateAddressAsync } from './actions';
import { IAddress, IAddressState, IPrevAddress, IResidentialResponse } from './types';

export const initialAddress: IAddress = {
    city: '',
    monthsAtResidence: 0,
    postalCode: '',
    province: '',
    streetAddress: '',
    unit: '',
    yearsAtResidence: 0,
};

export const initialState: IAddressState = {
    address: initialAddress,
    history: [],
    loading: true,
    updated: false,
};

export const addressSlice = createSlice({
    extraReducers: (builder: any) => {
        builder.addCase(getAddressAsync.pending, (state: any) => {
            state.loading = true;
        });

        builder.addCase(getAddressAsync.fulfilled, (state: any, action: any) => {
            state.loading = false;

            if (action.payload) {
                if (isEmpty(action.payload.unit)) {
                    action.payload.unit = '';
                }
                state.address = { ...action.payload };
                if (state.address) {
                    state.address.city = startCase(state.address.city?.toLowerCase());
                    state.address.streetAddress = startCase(state.address.streetAddress?.toLowerCase());
                }
            }
        });
        builder.addCase(getAddressAsync.rejected, (state: any) => {
            state.loading = false;
            state.updated = false;
        });
        builder.addCase(getResidentialInfoAsync.pending, (state: IAddressState) => {
            state.loading = true;
        });
        builder.addCase(
            getResidentialInfoAsync.fulfilled,
            (state: IAddressState, action: PayloadAction<IResidentialResponse>) => {
                state.loading = false;

                if (action.payload) {
                    state.address.city = startCase(action.payload.city?.toLowerCase());
                    state.address.streetAddress = startCase(action.payload.address1?.toLowerCase());
                    state.address.province = action.payload.state;
                    state.address.postalCode = action.payload.zip;
                    state.address.monthsAtResidence = state.address.monthsAtResidence || 0;
                    state.address.yearsAtResidence = state.address.yearsAtResidence || 0;
                    state.address.unit = '';
                }
            }
        );
        builder.addCase(getResidentialInfoAsync.rejected, (state: IAddressState) => {
            state.loading = false;
            state.updated = false;
        });
        builder.addCase(updateAddressAsync.fulfilled, (state: any, action: any) => {
            state.address = { ...action.payload };
            state.updated = true;
        });
        builder.addCase(updateAddressAsync.rejected, (state: any) => {
            state.loading = false;
            state.updated = false;
        });
        builder.addCase(ON_RESET_EVENT, () => initialState);
    },
    initialState,
    name: 'address',
    reducers: {
        addHistory(state, action: PayloadAction<IPrevAddress>) {
            const updatedHistory: Array<IPrevAddress> = state.history ?? [];
            updatedHistory.push(action.payload);
            state.history = updatedHistory;
        },
        deleteHistory(state, action: PayloadAction<IPrevAddress>) {
            const updatedHistory = state.history.filter((address) => address.id !== action.payload.id);
            state.history = updatedHistory;
        },
        setAddress(state, action: PayloadAction<IAddress>) {
            state.address = { ...action.payload };
        },
        setLoading(state, action: PayloadAction<boolean>) {
            state.loading = action.payload;
        },
        updateHistory(state, action: PayloadAction<IPrevAddress>) {
            const updatedHistory = state.history.map((address) =>
                address.id === action.payload.id ? action.payload : address
            );
            state.history = updatedHistory;
        },
    },
});
