import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types/store';

import { loginSlice } from './reducers';
import { ILoginState, TLoginname, TTokens } from './types';

export const loginState = (state: RootState): ILoginState => state.login;
export const selectName = (state: RootState): TLoginname => {
    const { name } = state.login;
    return { name };
};

export const selectNameMemoized = createSelector([selectName], (name) => name);

export const selectBlocked = (state: RootState): boolean => state.login?.blocked;
export const selectTokens = (state: RootState): TTokens => {
    const { token, 'x-fairstone-identity': xFairstoneIdentity } = state.login;
    return {
        token,
        'x-fairstone-identity': xFairstoneIdentity,
    };
};
export const { setBlockedState, setLoginState, setUsername } = loginSlice.actions;
export default loginSlice.reducer;
