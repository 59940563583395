import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ON_RESET_EVENT } from 'store/redux/actions';
import { ISegment } from 'types/customer';

import {
    resendCodeAsync,
    retrieveBannerDetailsAsync,
    retrieveUsernameAsync,
    verifyCodeAsync,
    verifyCustomerAsync,
} from './actions';
import { ICustomerInformation, ICustomerState, IRetrieveUsernameResponse, IVerifyCustomerResponse } from './types';

export const initialState: ICustomerState = {
    error: '',
    information: {
        accountNumber: '',
        customerId: '',
        day: '',
        email: '',
        firstName: '',
        lastName: '',
        middleName: '',
        month: '',
        postalCode: '',
        username: '',
        year: '',
    },
    loading: false,
    segments: [],
    ucpId: '',
    verificationKey: '',
};

export const customerSlice = createSlice({
    extraReducers: (builder: any) => {
        builder.addCase(verifyCustomerAsync.pending, (state: ICustomerState) => {
            state.loading = true;
            state.error = '';
            state.verificationKey = '';
        });
        builder.addCase(
            verifyCustomerAsync.fulfilled,
            (state: ICustomerState, action: PayloadAction<IVerifyCustomerResponse>) => {
                if (action.payload) {
                    const { ucpId = '', verificationKey = '' } = action.payload;

                    state.verificationKey = verificationKey;
                    state.ucpId = ucpId;
                }

                state.loading = false;
                state.error = '';
            }
        );
        builder.addCase(
            verifyCustomerAsync.rejected,
            (state: ICustomerState, action: PayloadAction<IVerifyCustomerResponse>) => {
                let errorFound = '';

                if (action.payload) {
                    const { error = '' } = action.payload;
                    errorFound = error;
                }

                state.loading = false;
                state.error = errorFound;
            }
        );
        builder.addCase(retrieveUsernameAsync.pending, (state: ICustomerState) => {
            state.loading = true;
            state.error = '';
            state.information.username = '';
        });
        builder.addCase(
            retrieveUsernameAsync.fulfilled,
            (state: ICustomerState, action: PayloadAction<IRetrieveUsernameResponse>) => {
                if (action.payload) {
                    const { username = '' } = action.payload;

                    state.information.username = username;
                }

                state.loading = false;
                state.error = '';
            }
        );
        builder.addCase(
            retrieveBannerDetailsAsync.fulfilled,
            (state: ICustomerState, action: PayloadAction<ISegment[]>) => {
                if (action.payload) {
                    state.segments = action.payload;
                }

                state.loading = false;
            }
        );
        builder.addCase(retrieveBannerDetailsAsync.pending, (state: ICustomerState) => {
            state.loading = true;
        });
        builder.addCase(retrieveBannerDetailsAsync.rejected, (state: ICustomerState) => {
            state.loading = false;
        });
        builder.addCase(
            retrieveUsernameAsync.rejected,
            (state: ICustomerState, action: PayloadAction<IRetrieveUsernameResponse>) => {
                state.loading = false;
                state.error = action.payload?.error ?? '';
            }
        );
        builder.addCase(verifyCodeAsync.pending, (state: ICustomerState) => {
            state.loading = true;
            state.error = '';
        });
        builder.addCase(verifyCodeAsync.fulfilled, (state: ICustomerState) => {
            state.loading = false;

            state.error = '';
        });
        builder.addCase(verifyCodeAsync.rejected, (state: ICustomerState, action: PayloadAction<string>) => {
            state.loading = false;
            state.error = action.payload;
        });
        builder.addCase(resendCodeAsync.pending, (state: ICustomerState) => {
            state.loading = true;
            state.error = '';
        });
        builder.addCase(resendCodeAsync.fulfilled, (state: ICustomerState) => {
            state.loading = false;
            state.error = '';
        });
        builder.addCase(resendCodeAsync.rejected, (state: ICustomerState, action: PayloadAction<string>) => {
            state.loading = false;
            state.error = action.payload;
        });

        builder.addCase(ON_RESET_EVENT, () => initialState);
    },
    initialState,
    name: 'customerSlice',
    reducers: {
        clearCustomerError(state) {
            state.error = '';
        },
        clearUsername(state) {
            state.information.username = '';
        },
        clearVerificationKey(state) {
            state.verificationKey = '';
        },
        setAccountNumber(state, action: PayloadAction<string>) {
            state.information.accountNumber = action.payload;
        },
        setCustomerInformation(state, action: PayloadAction<ICustomerInformation>) {
            state.information = { ...action.payload };
            state.error = '';
        },
        setCustomerUsername(state, action: PayloadAction<string>) {
            state.information.username = action.payload;
        },
        setUcpId(state, action: PayloadAction<string>) {
            state.ucpId = action.payload;
        },
    },
});
