// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Amplify, Auth } from 'aws-amplify';

import { isSessionStorageAvailable } from 'utils/sessionStorage';

import { COGNITO_CONFIG } from './constants';

Amplify.configure({
    Auth: {
        region: 'us-east-1',
        // if sessionStorage is disable storing info into localStorage
        storage: isSessionStorageAvailable() ? sessionStorage : null,
        userPoolId: COGNITO_CONFIG.poolID,
        userPoolWebClientId: COGNITO_CONFIG.clientID,
    },
});
