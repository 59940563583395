import { RootState } from 'types/store';

import { paymentsSlice } from './reducers';
import { IPaymentsState } from './types';

export const selectPayments = (state: RootState): IPaymentsState => state.payments;

export { deletePaymentAsync, getPaymentsAsync, setupNewPaymentAsync } from './actions';

export const {} = paymentsSlice.actions;

export default paymentsSlice.reducer;
