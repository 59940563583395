import { IntlShape } from 'react-intl';
import { translate } from '@fairstone/ui/core/utils/translate';
import { addBusinessDays } from 'date-fns';
import moment from 'moment';

import { ISummary } from 'types/account';
import {
    EPaymentFrequency,
    EPaymentSources,
    EPaymentTypes,
    IPaymentDateLimits,
    IPaymentSourceItem,
    IReviewItem,
    TPaymentInformation,
} from 'types/payments';
import { allOTPPaymentSources, allRecurringSources } from 'utils/constants';
import { formatAccountNumber, formatNumberOptions, stripFormatting } from 'utils/format';

const getOTPPaymentSources = (summary: ISummary) => {
    const { amountPastDue, monthlyPaymentAmount, paymentDue } = summary;
    const filteredSources = allOTPPaymentSources.filter((source) => {
        let isIncluded = false;

        switch (source) {
            case EPaymentSources.CURRENT:
                isIncluded = paymentDue > 0;
                break;
            case EPaymentSources.MONTHLY:
                isIncluded = monthlyPaymentAmount > 0;
                break;
            case EPaymentSources.PAST_DUE:
                isIncluded = amountPastDue > 0;
                break;
        }

        return isIncluded;
    });

    return filteredSources.map((source) => {
        const key = source as keyof ISummary;
        const value = Number(summary[key]).toFixed(2).toString();

        return {
            key: source,
            value,
        };
    });
};

const getRecurringPaymentSources = (summary: ISummary) => {
    const { monthlyPaymentAmount } = summary;

    return allRecurringSources.map((source) => {
        let value = 0;

        switch (source) {
            case EPaymentFrequency.MONTHLY:
                value = monthlyPaymentAmount;
                break;
            case EPaymentFrequency.BIWEEKLY:
                value = (monthlyPaymentAmount * 12) / 24;
                break;
            case EPaymentFrequency.SEMIMONTHLY:
                value = monthlyPaymentAmount / 2;
                break;
        }

        const item: IPaymentSourceItem = {
            key: source,
            value: value.toFixed(2).toString(),
        };

        return item;
    });
};

export const getPaymentSources = (summary: ISummary, paymentType: EPaymentTypes): IPaymentSourceItem[] =>
    paymentType === EPaymentTypes.OTP ? getOTPPaymentSources(summary) : getRecurringPaymentSources(summary);

export const getMinMaxDates = (isRecurring: boolean, nextPaymentDate?: string): IPaymentDateLimits => {
    const today = moment().toDate();

    if (isRecurring) {
        return {
            max: moment(nextPaymentDate, 'MM/DD/YYYY').toDate(),
            min: addBusinessDays(today, 2),
        };
    }

    return {
        max: moment(today).add(59, 'days').toDate(),
        min: moment(today).toDate(),
    };
};

export const getValidDate = (currDate: Date, dateRange: IPaymentDateLimits, isDirty: boolean): Date => {
    if (!isDirty) {
        return dateRange.min;
    } else {
        const min = moment(dateRange.min);
        const max = moment(dateRange.max);
        const curr = moment(currDate);

        if (curr.isAfter(max) || curr.isBefore(min)) return dateRange.min;
    }

    return currDate;
};

export const getPaymentReviewDetails = (data: TPaymentInformation, intl: IntlShape): IReviewItem[] => {
    const { accountNumber, nickname, paymentAmount, paymentDate, paymentSource, paymentType } = data;

    const isRecurring = paymentType === EPaymentTypes.RECURRING_PAYMENTS;
    const paymentDateKey = isRecurring ? 'recurringPayment' : 'oneTimePayment';

    const formattedSource = `${nickname}: ${formatAccountNumber(accountNumber).toLowerCase()}`;
    const numberStr = stripFormatting(paymentAmount);
    const formattedAmount = intl.formatNumber(Number(numberStr), formatNumberOptions);
    const formattedDate = intl.formatDate(moment(paymentDate).toDate(), {
        day: 'numeric',
        month: 'long',
        year: 'numeric',
    });

    const reviewDetails: IReviewItem[] = [
        { data: formattedDate, title: `paymentDate.${paymentDateKey}` },
        { data: formattedAmount, title: 'paymentAmountLabel' },
        { data: formattedSource, title: 'paymentSourceLabel' },
    ];

    if (isRecurring) {
        const formattedFrequency = translate(
            `pages.payment-manager.screens.addPayment.recurringPayment.${paymentSource}`
        );
        reviewDetails.push({ data: formattedFrequency, title: 'paymentFrequencyLabel' });
    }

    return reviewDetails;
};
