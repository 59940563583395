import { RootState } from 'types/store';

import { documentVerificationSlice, IDocumentSectionVerificationState } from './reducers';

export const selectDocumentVerification = (state: RootState): IDocumentSectionVerificationState =>
    state.documentVerification;
export { getUrlsAndUploadDocumentsAsync } from './actions';

export const { clearRequiredDocuments, setRequiredDocuments } = documentVerificationSlice.actions;

export default documentVerificationSlice.reducer;
