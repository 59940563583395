import TrackJS from '@fairstone-frontend/utils/core/logs/trackjs';
import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

import { restAPI } from 'services/api';
import {
    DocumentTypeEnum,
    IApplicationRequest,
    IDocumentSection,
    IUploadRequest,
} from 'store/redux/modules/documentVerification/types';

import { setApplicationError } from '../application';

const getUrlsAndUploadDocuments = async (
    file: File,
    type: DocumentTypeEnum,
    application: IApplicationRequest
): Promise<boolean> => {
    const { applicationNumber, branchNumber } = application;
    try {
        if (file) {
            const extension = (file.name.split('.').pop() || '').toLocaleLowerCase();
            const param = {
                applicationNumber,
                branchNumber,
                extension,
                locale: 'en',
                type,
            };
            const { data } = await restAPI.post('/non-glo/document-link', param);
            if (data.url) {
                // and then we upload the document
                await axios.put(data.url, file, {
                    data: {
                        ...file,
                        type,
                    } as any,
                });
            }
        }
    } catch (error: any) {
        TrackJS.track(error);
        return false;
    }
    return true;
};

export const getUrlsAndUploadDocumentsAsync = createAsyncThunk(
    'documentVerification/getUrls',
    async (uploadRequest: IUploadRequest, { dispatch, rejectWithValue }) => {
        const { application, sections } = uploadRequest;
        const errors: DocumentTypeEnum[] = [];
        try {
            await Promise.all(
                sections.map(async (section: IDocumentSection) => {
                    const { documents, type } = section;
                    await Promise.all(
                        documents.map(async (document) => {
                            if (!document.uploaded) {
                                const uploaded = await getUrlsAndUploadDocuments(document.file, type, application);
                                if (!uploaded) {
                                    errors.push(section.type);
                                }
                            }
                        })
                    );
                })
            );

            return errors;
        } catch (error: any) {
            TrackJS.track(error);
            dispatch(setApplicationError(true));
            return rejectWithValue(error.response.data);
        }
    }
);
