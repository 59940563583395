import { EnvEnum } from '@fairstone-frontend/utils/core/types';

import { AppContextEnum } from 'store/redux/modules/types';

export const TOKEN_NAME = process.env.REACT_APP_NAME as string;
export const API_URL = process.env.REACT_APP_API_URL;
export const OAM_URL = process.env.REACT_APP_API_URL?.replace('/omni', '');

export const BANNER_IMAGE_URL = process.env.REACT_APP_BANNER_IMAGE_URL || '';

export const ROAM_API_URL = process.env.REACT_APP_ROAM_API_URL || '';
export const ROAM_API_KEY = process.env.REACT_APP_ROAM_API_KEY || '';

export const DOCPORTAL_API_URL = process.env.REACT_APP_DOCPORTAL_API_URL || '';
export const DOCPORTAL_API_KEY = process.env.REACT_APP_DOCPORTAL_API_KEY || '';

export const CANADA_POST_API_KEY = process.env.REACT_APP_CANADA_POST_KEY
    ? (process.env.REACT_APP_CANADA_POST_KEY as string)
    : '';

export const IS_DEV_ENV =
    window?.location.hostname.includes('localhost') || window?.location.hostname.includes('.local');

export const CAN_RESTART_FLOW = process.env.REACT_APP_CAN_RETRY_FLOW === 'true' ? true : false;
export const CAN_FORCE_GLO = process.env.REACT_APP_CAN_FORCE_GLO === 'true' ? true : false;
export const PUBLIC = process.env.PUBLIC_URL;
export const GTM_ID = process.env.REACT_APP_GTM_ID || '';
export const RETRY_COUNT = process.env.REACT_APP_RETRY_COUNT ? parseInt(process.env.REACT_APP_RETRY_COUNT) : 3;
export const SESSION_TIMEOUT_POPUP = process.env.REACT_APP_SESSION_TIMEOUT_POPUP
    ? parseFloat(process.env.REACT_APP_SESSION_TIMEOUT_POPUP)
    : 5;
export const SESSION_TIMEOUT_LOGOUT = process.env.REACT_APP_SESSION_TIMEOUT_LOGOUT
    ? parseFloat(process.env.REACT_APP_SESSION_TIMEOUT_LOGOUT)
    : 1.5;

export const ACTIVATE_SESSION_TIMEOUT = process.env.REACT_APP_ACTIVATE_SESSION_TIMEOUT
    ? Boolean(process.env.REACT_APP_ACTIVATE_SESSION_TIMEOUT)
    : IS_DEV_ENV
      ? false
      : true;

export const TRACKJS_KEY = process.env.REACT_APP_TRACKJS_KEY || '';
export const TRACKJS_APPNAME = process.env.REACT_APP_TRACKJS_APPNAME || '';
export const LOGROCKET_APPNAME = process.env.REACT_APP_LOGROCKET_APPNAME || '';
export const DYNATRACE_URL = process.env.REACT_APP_DYNATRACE_URL || '';

export const CURRENT_ENV = process.env.REACT_APP_CURRENT_ENV
    ? (process.env.REACT_APP_CURRENT_ENV as EnvEnum)
    : EnvEnum.LOCAL;

export const REACT_APP_FEATURE_PROVIDER = process.env.REACT_APP_FEATURE_PROVIDER
    ? JSON.parse(process.env.REACT_APP_FEATURE_PROVIDER)
    : {};

export const FLINKS_IFRAME = process.env.REACT_APP_FLINKS_IFRAME || '';

export const APP_CONTEXT = process.env.REACT_APP_CONTEXT || AppContextEnum.OMNI;

export const COGNITO_CONFIG = process.env.REACT_APP_COGNITO_CONFIG
    ? JSON.parse(process.env.REACT_APP_COGNITO_CONFIG)
    : {};

export const BYPASS_NAVIGATION = process.env.REACT_APP_BYPASS_NAVIGATION === 'true' ? true : false;

export const GLIA_ENABLED = process.env.REACT_APP_GLIA_ENABLED === 'true';
