import { RootState } from 'types/store';

import { bankSlice } from './reducers';
import { IBankState } from './types';

export const selectBank = (state: RootState): IBankState => state.bank;
export { startLoanClosingAsync, updateBankAsync } from './actions';
export const { setLoading } = bankSlice.actions;

export default bankSlice.reducer;
