import { isEmpty } from 'lodash';

import { StepNameEnum } from './types';

export interface FlowManager {
    current: StepNameEnum;
    previous: StepNameEnum;
}

export type FlowSteps = {
    [key in StepNameEnum]?: StepNameEnum;
};

export const OMNI_FLOW_ROUTES = [
    StepNameEnum.OFFER_PAGE,
    StepNameEnum.ADDRESS_PAGE,
    StepNameEnum.PRODUCT_SELECT,
    StepNameEnum.CREDIT_CHECK,
    StepNameEnum.BANK_INFORMATION,
    StepNameEnum.THANK_YOU,
    StepNameEnum.CREDIT_CHECK_SOFT,
    StepNameEnum.UPLOAD_DOCUMENTS,
    StepNameEnum.PROPERTY,
    StepNameEnum.INCOME,
];

export const GloFlow: FlowSteps = {
    ADDRESS_PAGE: StepNameEnum.CREDIT_CHECK,
    BANK_INFORMATION: StepNameEnum.THANK_YOU,
    CREDIT_CHECK: StepNameEnum.PRODUCT_SELECT,
    OFFER_PAGE: StepNameEnum.ADDRESS_PAGE,
    PRODUCT_SELECT: StepNameEnum.BANK_INFORMATION,
};

export const NonGloFlow: FlowSteps = {
    ADDRESS_PAGE: StepNameEnum.PROPERTY,
    BANK_INFORMATION: StepNameEnum.THANK_YOU,
    CREDIT_CHECK: StepNameEnum.UPLOAD_DOCUMENTS,
    CREDIT_CHECK_SOFT: StepNameEnum.PRODUCT_SELECT,
    INCOME: StepNameEnum.CREDIT_CHECK_SOFT,
    OFFER_PAGE: StepNameEnum.ADDRESS_PAGE,
    PRODUCT_SELECT: StepNameEnum.CREDIT_CHECK,
    PROPERTY: StepNameEnum.INCOME,
    UPLOAD_DOCUMENTS: StepNameEnum.BANK_INFORMATION,
};

export const RposFlow: FlowSteps = {
    ADDRESS_PAGE: StepNameEnum.PROPERTY,
    BANK_INFORMATION: StepNameEnum.THANK_YOU,
    CREDIT_CHECK: StepNameEnum.PRODUCT_SELECT,
    DEMOGRAPHIC: StepNameEnum.ADDRESS_PAGE,
    ID_VERIFICATION: StepNameEnum.BANK_INFORMATION,
    INCOME: StepNameEnum.CREDIT_CHECK,
    OFFER_PAGE: StepNameEnum.DEMOGRAPHIC,
    PRODUCT_SELECT: StepNameEnum.ID_VERIFICATION,
    PROPERTY: StepNameEnum.INCOME,
};

export enum FlowEnum {
    GLO = 'GLO',
    NONGLO = 'NONGLO',
    RPOS = 'RPOS',
}

export type ApplicationType = 'glo' | 'non-glo' | 'rpos' | null;
export let applicationType: ApplicationType = null;

export let flowMap: Map<StepNameEnum, number>;
let previousPage: Map<StepNameEnum, StepNameEnum>;

// eslint-disable-next-line prefer-const
export let currentFlowEnum: FlowEnum | null = null;

export let currentFlow = GloFlow;

export const updateApplicationFlow = (flowEnum: FlowEnum, forceUpdate?: boolean): void => {
    if ((currentFlowEnum !== flowEnum && !isEmpty(flowEnum)) || forceUpdate) {
        currentFlowEnum = flowEnum;

        currentFlow = getCurrentFlow(flowEnum);

        const pageOrder = new Map<StepNameEnum, number>();
        previousPage = new Map<StepNameEnum, StepNameEnum>();

        let index = 1;
        pageOrder.set(StepNameEnum.OFFER_PAGE, index++);

        let currentPage = StepNameEnum.OFFER_PAGE;

        while (currentPage !== StepNameEnum.THANK_YOU) {
            const nextPage = currentFlow[currentPage] as StepNameEnum;
            if (nextPage) {
                previousPage.set(nextPage, currentPage);
                currentPage = nextPage;
                pageOrder.set(currentPage, index++);
            } else break;
        }

        flowMap = pageOrder;
    }
};

const getCurrentFlow = (flow: FlowEnum): FlowSteps => {
    switch (flow) {
        case FlowEnum.GLO:
            applicationType = 'glo';
            return GloFlow;
        case FlowEnum.NONGLO:
            applicationType = 'non-glo';
            return NonGloFlow;
        case FlowEnum.RPOS:
            applicationType = 'rpos';
            return RposFlow;
    }
};

export const getPreviousPage = (step: StepNameEnum | null): StepNameEnum => {
    if (!isEmpty(previousPage)) {
        if (step !== null) return previousPage.get(step) ?? StepNameEnum.OFFER_PAGE;
    } else if (currentFlowEnum !== null) {
        // Update Flow if previousPage is empty and currentFlow != null
        updateApplicationFlow(currentFlowEnum, true);
        return getPreviousPage(step);
    }

    return StepNameEnum.OFFER_PAGE;
};

// Page Redirects & Current Page

// eslint-disable-next-line prefer-const
export let currentPage: StepNameEnum | null = null;
export let redirectToNextStep = false;

export const setRedirectToNextStep = (redirect: boolean): void => {
    redirectToNextStep = redirect;
};

export const setCurrentStep = (step: StepNameEnum, redirect?: boolean): void => {
    redirectToNextStep = redirect ?? false;
    currentPage = step;
};

export const resetCurrentStep = (): void => {
    redirectToNextStep = false;
    currentPage = null;
};
