import { isEmpty } from 'lodash';

import { IEmployment } from 'store/redux/modules/income/types';

// only 1 years ago from now
export const isCurrentEmployment = (employment: IEmployment): boolean => {
    const sinceDate = new Date();
    sinceDate.setFullYear(sinceDate.getFullYear() - 1);

    // all current employment & old jobs that ended in the last year
    return (
        (employment.currentEmployment &&
            (employment.income?.incomeSource === 'EMP' || employment.income?.incomeSource === 'AEMP')) ||
        (isEmpty(employment.income?.incomeSource) &&
            (new Date(employment.employmentEndDate) >= sinceDate || isEmpty(employment.employmentEndDate)))
    );
};
