import { addressSlice } from 'store/redux/modules/address/reducers';
import { RootState } from 'types/store';

import { IAddressState } from './types';

export const selectAddress = (state: RootState): IAddressState => state.address;
export { getAddressAsync, getResidentialInfoAsync, updateAddressAsync } from './actions';

export const { addHistory, deleteHistory, setAddress, setLoading, updateHistory } = addressSlice.actions;

export default addressSlice.reducer;
