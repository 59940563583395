import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ON_RESET_EVENT } from 'store/redux/actions';
import { IPaymentDetails } from 'types/payments';

import { deletePaymentAsync, editPaymentAsync, getPaymentsAsync, setupNewPaymentAsync } from './actions';
import { IDeletePaymentResponse, IPaymentsState } from './types';

export const initialState: IPaymentsState = {
    error: false,
    loading: true,
    payments: [] as IPaymentDetails[],
    paymentStatus: {
        isLoading: false,
        isSuccess: false,
    },
};

export const paymentsSlice = createSlice({
    extraReducers: (builder: any) => {
        /* deletePayment */
        builder.addCase(deletePaymentAsync.pending, (state: IPaymentsState) => {
            state.loading = true;
            state.error = false;
        });

        builder.addCase(
            deletePaymentAsync.fulfilled,
            (state: IPaymentsState, action: PayloadAction<IDeletePaymentResponse>) => {
                if (action.payload) {
                    const { payment } = action.payload;
                    const updatedPayments = state.payments.filter(
                        (p) => p.paymentConfirmationNumber !== payment.paymentConfirmationNumber
                    );

                    updatedPayments.push({ ...payment, paymentIsPending: true });
                    state.payments = updatedPayments;
                }
                state.error = false;
                state.loading = false;
            }
        );
        builder.addCase(deletePaymentAsync.rejected, (state: IPaymentsState) => {
            state.loading = false;
            state.error = true;
        });
        /* getPayments */
        builder.addCase(getPaymentsAsync.pending, (state: IPaymentsState) => {
            state.loading = true;
        });

        builder.addCase(getPaymentsAsync.fulfilled, (state: IPaymentsState, action: any) => {
            state.loading = false;

            if (action.payload) {
                state.payments = action.payload.payments;
            }
        });

        builder.addCase(getPaymentsAsync.rejected, (state: IPaymentsState) => {
            state.loading = false;
        });

        /* setupNewPayments */
        builder.addCase(setupNewPaymentAsync.pending, (state: IPaymentsState) => {
            state.paymentStatus.isLoading = true;
        });

        builder.addCase(setupNewPaymentAsync.fulfilled, (state: IPaymentsState, action: any) => {
            state.paymentStatus.isLoading = false;
            state.paymentStatus.isSuccess = !!action.payload;
        });

        builder.addCase(setupNewPaymentAsync.rejected, (state: IPaymentsState) => {
            state.paymentStatus.isLoading = false;
            state.paymentStatus.isSuccess = false;
        });

        /* editPayment */
        builder.addCase(editPaymentAsync.pending, (state: IPaymentsState) => {
            state.paymentStatus.isLoading = true;
        });

        builder.addCase(editPaymentAsync.fulfilled, (state: IPaymentsState, action: any) => {
            state.paymentStatus.isLoading = false;
            state.paymentStatus.isSuccess = !!action.payload;
        });

        builder.addCase(editPaymentAsync.rejected, (state: IPaymentsState) => {
            state.paymentStatus.isLoading = false;
            state.paymentStatus.isSuccess = false;
        });

        /* other */
        builder.addCase(ON_RESET_EVENT, () => initialState);
    },
    initialState,
    name: 'payments',
    reducers: {},
});
