import TrackJS from '@fairstone-frontend/utils/core/logs/trackjs';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';

import { restAPI } from 'services/api';

import { setApplicationError } from '../application';

import {
    IHousingGetRequestPayload,
    IHousingGetResponsePayload,
    IHousingInfoRequest,
    IHousingInfoResponse,
    IHousingInfoUpdateRequest,
    IHousingInfoUpdateResponse,
    IHousingUpdateRequestPayload,
    IHousingUpdateResponsePayload,
} from './types';

export const getHousing = async (
    housing: IHousingGetRequestPayload
): Promise<AxiosResponse<IHousingGetResponsePayload> | null> => {
    let response: AxiosResponse<IHousingGetResponsePayload> | null = null;
    try {
        response = await restAPI.get('/user/housing-info', {
            params: {
                ...housing,
            },
        });
    } catch (error: any) {
        TrackJS.track(error);
    }

    return response;
};

export const updateHousing = async (
    housing: IHousingUpdateRequestPayload
): Promise<AxiosResponse<IHousingUpdateResponsePayload> | null> => {
    let response: AxiosResponse<IHousingUpdateResponsePayload> | null = null;
    try {
        response = await restAPI.put('/user/housing-info', {
            ...housing,
        });
    } catch (error: any) {
        TrackJS.track(error);
    }
    return response;
};

export const getHousingAsync = createAsyncThunk(
    'housing/fetchHousing',
    async (housing: IHousingGetRequestPayload, { rejectWithValue }) => {
        const response = await getHousing(housing);

        if (response && response?.data) {
            return response?.data;
        }

        return rejectWithValue(response);
    }
);

export const updateHousingAsync = createAsyncThunk(
    'housing/updateHousing',
    async (housing: IHousingUpdateRequestPayload, { dispatch, rejectWithValue }) => {
        const response = await updateHousing(housing);

        if (response && response?.data) {
            return response?.data;
        }

        dispatch(setApplicationError(true));
        return rejectWithValue(response);
    }
);

const getRPOSHousing = async (request: IHousingInfoRequest): Promise<AxiosResponse<IHousingInfoResponse> | null> => {
    let response: AxiosResponse<IHousingInfoResponse> | null = null;
    try {
        response = await restAPI.get('/user/housing-information', {
            params: {
                ...request,
            },
        });
    } catch (error: any) {
        TrackJS.track(error);
    }

    return response;
};

const updateRPOSHousing = async (
    housing: IHousingInfoUpdateRequest
): Promise<AxiosResponse<IHousingInfoUpdateResponse> | null> => {
    let response: AxiosResponse<IHousingInfoUpdateResponse> | null = null;
    try {
        response = await restAPI.post('/user/housing-information', {
            ...housing,
        });
    } catch (error: any) {
        TrackJS.track(error);
    }
    return response;
};

export const getRPOSHousingAsync = createAsyncThunk(
    'housing/getHousingInfo',
    async (request: IHousingInfoRequest, { rejectWithValue }) => {
        const response = await getRPOSHousing(request);

        if (response && response?.data) {
            return response?.data;
        }

        return rejectWithValue(response);
    }
);

export const updateRPOSHousingAsync = createAsyncThunk(
    'housing/updateHousingInfo',
    async (housing: IHousingInfoUpdateRequest, { rejectWithValue }) => {
        const response = await updateRPOSHousing(housing);

        if (response && response?.data) {
            return response?.data;
        }

        return rejectWithValue(response);
    }
);
