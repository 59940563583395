import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import isEmpty from 'lodash/isEmpty';

import { ON_RESET_EVENT } from 'store/redux/actions';

import {
    getProductInsuranceAsync,
    getRPOSOptionalProductAsync,
    getRPOSProductOfferAsync,
    updateProductTermsAsync,
    updateRPOSOptionalProductAsync,
    updateRPOSProductOfferAsync,
} from './actions';
import {
    InsuranceType,
    IProductServiceResponse,
    IProductServiceUpdateResponse,
    IRposOptionalInsuranceProduct,
    IRposOptionalProduct,
    IRPOSOptionalProductResponse,
    IRPOSProductOfferResponse,
    ProductInsuranceEnum,
} from './types';

export interface IProductInsuranceState {
    productInsurance: IProductServiceResponse;
    insurances: IRposOptionalProduct;
    loading: boolean;
    error: boolean;
    productUpdated: boolean;
    selectedTerm: number;
}

export const initialState: IProductInsuranceState = {
    error: false,
    insurances: {
        monthlyPaymentWithInsurance: 0,
        monthlyPaymentWithoutInsurance: 0,
        optionalProduct: [],
    },
    loading: true,
    productInsurance: {
        amount: 0,
        consolidatedDebt: 0,
        products: [],
    },
    productUpdated: false,
    selectedTerm: 60,
};

export const productInsuranceSlice = createSlice({
    extraReducers: (builder: any) => {
        builder.addCase(getProductInsuranceAsync.pending, (state: IProductInsuranceState) => {
            state.loading = true;
        });

        builder.addCase(
            getProductInsuranceAsync.fulfilled,
            (state: IProductInsuranceState, action: PayloadAction<IProductServiceResponse>) => {
                if (isEmpty(action.payload) || !action.payload.products) {
                    state.error = true;
                } else {
                    state.error = false;
                    state.selectedTerm = action.payload.products[0].term;
                }

                state.loading = false;
                state.productInsurance = { ...action.payload };
            }
        );

        builder.addCase(getProductInsuranceAsync.rejected, (state: IProductInsuranceState) => {
            state.error = true;
            state.loading = false;
            state.productInsurance = {
                amount: 0,
                consolidatedDebt: 0,
                products: [],
            };
        });

        builder.addCase(getRPOSProductOfferAsync.pending, (state: IProductInsuranceState) => {
            state.loading = true;
        });

        builder.addCase(
            getRPOSProductOfferAsync.fulfilled,
            (state: IProductInsuranceState, action: PayloadAction<IRPOSProductOfferResponse>) => {
                if (isEmpty(action.payload) || action.payload.length === 0) {
                    state.error = true;
                } else {
                    state.error = false;
                    state.selectedTerm = action.payload[0].term;
                    state.productInsurance = {
                        amount: action.payload[0].amount,
                        consolidatedDebt: action.payload[0].consolidatedDebt,
                        products: action.payload.map((product) => ({
                            disabilityInsuranceOptedFlag: ProductInsuranceEnum.NOT_COVERED,
                            disabilityInsurancePremiumOptedAmount: 0,
                            insuranceOptedFlag: ProductInsuranceEnum.NOT_COVERED,
                            insuranceOptedPremiumAmount: 0,
                            lifeInsuranceOptedFlag: ProductInsuranceEnum.NOT_COVERED,
                            lifeInsuranceOptedPremiumAmount: 0,
                            monthlyPaymentWithInsurance: product.monthlyPaymentWithInsurance,
                            monthlyPaymentWithoutInsurance: product.monthlyPaymentWithoutInsurance,
                            overrideFlag: product.overrideFlag,
                            term: product.term,
                        })),
                    };
                }

                state.loading = false;
            }
        );

        builder.addCase(getRPOSProductOfferAsync.rejected, (state: IProductInsuranceState) => {
            state.error = true;
            state.loading = false;
            state.productInsurance = {
                amount: 0,
                consolidatedDebt: 0,
                products: [],
            };
        });

        builder.addCase(
            getRPOSOptionalProductAsync.fulfilled,
            (state: IProductInsuranceState, action: PayloadAction<IRPOSOptionalProductResponse>) => {
                const { monthlyPaymentWithInsurance, monthlyPaymentWithoutInsurance, optionalProduct } = action.payload;
                const optionalProductTranslated = optionalProduct.map((product) => {
                    let translate = '';
                    switch (product.productType) {
                        case InsuranceType.Disability:
                            translate = 'disability';
                            break;
                        case InsuranceType.LifeInsurance:
                            translate = 'life';
                            break;
                        case InsuranceType.Unemployment:
                            translate = 'unemployment';
                            break;
                    }

                    const insurance: IRposOptionalInsuranceProduct = {
                        opted: '',
                        premium: product.premium,
                        productType: product.productType,
                        translate,
                    };
                    return insurance;
                });

                state.insurances = {
                    monthlyPaymentWithInsurance,
                    monthlyPaymentWithoutInsurance,
                    optionalProduct: optionalProductTranslated,
                };
                state.loading = false;
            }
        );

        builder.addCase(getRPOSOptionalProductAsync.pending, (state: IProductInsuranceState) => {
            state.loading = true;
        });

        builder.addCase(getRPOSOptionalProductAsync.rejected, (state: IProductInsuranceState) => {
            state.error = true;
            state.loading = false;
            state.productInsurance = {
                amount: 0,
                consolidatedDebt: 0,
                products: [],
            };
        });

        builder.addCase(updateProductTermsAsync.pending, (state: IProductInsuranceState) => {
            state.loading = true;
        });

        builder.addCase(
            updateProductTermsAsync.fulfilled,
            (state: IProductInsuranceState, action: PayloadAction<IProductServiceUpdateResponse>) => {
                state.productUpdated = action.payload.updated;
                state.loading = false;
            }
        );

        builder.addCase(updateProductTermsAsync.rejected, (state: IProductInsuranceState) => {
            state.loading = false;
            state.error = true;
            state.productUpdated = false;
        });

        builder.addCase(updateRPOSOptionalProductAsync.pending, (state: IProductInsuranceState) => {
            state.loading = true;
        });

        builder.addCase(updateRPOSOptionalProductAsync.fulfilled, (state: IProductInsuranceState) => {
            state.loading = false;
        });

        builder.addCase(updateRPOSOptionalProductAsync.rejected, (state: IProductInsuranceState) => {
            state.loading = false;
            state.error = true;
        });

        builder.addCase(updateRPOSProductOfferAsync.pending, (state: IProductInsuranceState) => {
            state.loading = true;
        });

        builder.addCase(
            updateRPOSProductOfferAsync.fulfilled,
            (state: IProductInsuranceState, action: PayloadAction<IRPOSProductOfferResponse>) => {
                if (isEmpty(action.payload)) {
                    state.error = true;
                    state.productUpdated = false;
                } else {
                    state.error = false;
                    state.productUpdated = true;
                }
                state.loading = false;
            }
        );

        builder.addCase(updateRPOSProductOfferAsync.rejected, (state: IProductInsuranceState) => {
            state.loading = false;
            state.error = true;
            state.productUpdated = false;
        });

        builder.addCase(ON_RESET_EVENT, () => initialState);
    },
    initialState,
    name: 'productInsurance',
    reducers: {
        setSelectedTerm(state, action: PayloadAction<number>) {
            state.selectedTerm = action.payload;
        },
    },
});
