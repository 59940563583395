import moment from 'moment';

import { EPaymentFrequency, IOTPPayload, IRecurringPayload, TPaymentInformation } from 'types/payments';
import { stripFormatting } from 'utils/format';

export const buildOTPPayload = (data: TPaymentInformation): IOTPPayload => {
    const {
        accountNumber,
        institutionNumber,
        nickname: paymentSourceName,
        paymentAmount: currency,
        paymentDate,
        transitNumber,
    } = data;

    const formattedDate = moment(paymentDate).format();

    return {
        additionalAmount: 0,
        isPayoffPayment: false,
        newPaymentDebitDetails: {
            accountNumber,
            institutionNumber,
            paymentSourceName,
            savePaymentSource: false,
            transitNumber,
        },
        paymentAmount: stripFormatting(currency),
        paymentDate: formattedDate,
        paymentFrequency: EPaymentFrequency.ONCE,
        paymentIsRecurring: false,
        paymentSourceId: '',
    };
};

export const buildOTPEditPayload = (data: TPaymentInformation): IOTPPayload => {
    const {
        accountNumber,
        institutionNumber,
        nickname: paymentSourceName,
        paymentAmount: currency,
        paymentDate,
        paymentId,
        paymentSourceId,
        transitNumber,
    } = data;

    const formattedDate = moment(paymentDate).format();

    const payload: IOTPPayload = {
        additionalAmount: 0,
        isPayoffPayment: false,
        newPaymentDebitDetails: {
            accountNumber,
            institutionNumber,
            paymentSourceName,
            savePaymentSource: false,
            transitNumber,
        },
        paymentAmount: stripFormatting(currency),
        paymentDate: formattedDate,
        paymentFrequency: EPaymentFrequency.ONCE,
        paymentId,
        paymentIsRecurring: false,
        paymentSourceId,
    };

    return payload;
};

export const buildRecurringPayload = (data: TPaymentInformation): IRecurringPayload => {
    const {
        accountDetails: { payOffAttemptFlag: isPayoffPayment = false } = {},
        accountNumber,
        additionalAmount,
        institutionNumber,
        nickname: paymentSourceName,
        paymentAmount: currency,
        paymentDate,
        paymentSource: paymentFrequency,
        transitNumber,
    } = data;

    const formattedDate = moment(paymentDate).format();

    return {
        additionalAmount: stripFormatting(additionalAmount),
        isPayoffPayment,
        newPaymentDebitDetails: {
            accountNumber,
            institutionNumber,
            paymentSourceName,
            savePaymentSource: false,
            transitNumber,
        },
        paymentAmount: stripFormatting(currency),
        paymentDate: formattedDate,
        paymentFrequency,
        paymentIsRecurring: true,
        paymentSourceId: '',
    };
};
