import TrackJS from '@fairstone-frontend/utils/core/logs/trackjs';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { isEmpty } from 'lodash';

import { restAPI } from 'services/api';

import { setApplicationError } from '../application';
import { DocumentTypeEnum } from '../documentVerification/types';

import { initEmploymentState } from './reducers';
import {
    IAdditionalIncome,
    IEmployment,
    IEmploymentStatus,
    IGetEmploymentRequestPayload,
    IGetEmploymentResponsePayload,
    IGetRposEmploymentRequestPayload,
    IncomeCode,
    IncomeCodeTypes,
    IUpdateEmploymentRequestPayload,
    IUpdateEmploymentResponsePayload,
    IUpdateRposEmploymentRequestPayload,
} from './types';

const getEmploymentInfo = async (employmentRequest: IGetEmploymentRequestPayload) => {
    let response: AxiosResponse<IGetEmploymentResponsePayload> | null = null;
    try {
        response = await restAPI.get('/user/employment-info', {
            params: employmentRequest,
        });
    } catch (error: any) {
        TrackJS.track(error);
    }

    return response;
};

const getRposEmploymentInfo = async (employmentRequest: IGetRposEmploymentRequestPayload) => {
    let response: AxiosResponse<IGetEmploymentResponsePayload> | null = null;
    try {
        response = await restAPI.get('/user/employment-information', {
            params: employmentRequest,
        });
    } catch (error: any) {
        TrackJS.track(error);
    }

    return response;
};

const updateEmploymentInfo = async (updateEmploymentRequest: IUpdateEmploymentRequestPayload) => {
    let response: AxiosResponse<IUpdateEmploymentResponsePayload> | null = null;
    try {
        response = await restAPI.put('/user/employment-info', updateEmploymentRequest);
    } catch (error: any) {
        TrackJS.track(error);
    }

    return response;
};

const updateRposEmploymentInfo = async (updateEmploymentRequest: IUpdateRposEmploymentRequestPayload) => {
    let response: AxiosResponse<IUpdateEmploymentResponsePayload> | null = null;
    try {
        response = await restAPI.post('/user/employment-information', updateEmploymentRequest);
    } catch (error: any) {
        TrackJS.track(error);
    }

    return response;
};

export const getEmploymentInfoAsync = createAsyncThunk(
    'users/getEmploymentInfo',
    async (employmentRequest: IGetEmploymentRequestPayload, { rejectWithValue }) => {
        const response = await getEmploymentInfo(employmentRequest);

        if (response && response?.data) {
            return response?.data;
        }

        return rejectWithValue(response);
    }
);

export const getRposEmploymentInfoAsync = createAsyncThunk(
    'users/getRposEmploymentInfo',
    async (employmentRequest: IGetRposEmploymentRequestPayload, { rejectWithValue }) => {
        const response = await getRposEmploymentInfo(employmentRequest);

        if (response && response?.data) {
            return response?.data;
        }

        return rejectWithValue(response);
    }
);

export const updateEmploymentInfoAsync = createAsyncThunk(
    'users/updateEmploymentInfo',
    async (updateEmploymentRequest: IUpdateEmploymentRequestPayload, { dispatch, rejectWithValue }) => {
        const response = await updateEmploymentInfo(updateEmploymentRequest);

        if (response && response?.data) {
            return response?.data;
        }

        dispatch(setApplicationError(true));
        return rejectWithValue(response);
    }
);

export const updateRposEmploymentInfoAsync = createAsyncThunk(
    'users/updateRposEmploymentInfo',
    async (updateEmploymentRequest: IUpdateRposEmploymentRequestPayload, { dispatch, rejectWithValue }) => {
        const response = await updateRposEmploymentInfo(updateEmploymentRequest);

        if (response && response?.data) {
            return response?.data;
        }

        dispatch(setApplicationError(true));
        return rejectWithValue(response);
    }
);

/* Helpers */
export const getAdditionalIncomeCode = (name: string): string => {
    for (const [k, v] of Object.entries(IncomeCode)) {
        if (k === name) return v;
    }
    return '';
};

export const setDefaultValue = (employment: IEmployment, isPrimary: boolean): IEmployment => {
    if (employment.currentEmployment) {
        employment = {
            ...employment,
            income: { ...employment.income, frequency: '1', incomeSource: isPrimary ? 'Emp' : 'AEmp' },
            jobTitle: isEmpty(employment.jobTitle) ? 'Employee' : employment.jobTitle,
        };
    } else {
        employment = {
            ...employment,
            income: {
                frequency: '',
                incomeSource: '',
                jobCategory: '',
                salary: 0,
            },
        };
    }

    return employment;
};

//if retirement checked in previous step, put job category as retirement or unemployed (income or without income) at the highest active income.
export const setPrimaryIncome = (
    employments: Array<IEmployment>,
    isAdditional: boolean,
    employmentStatus: IEmploymentStatus = initEmploymentState
): Array<IEmployment> => {
    employments.sort((a, b) => b.income.salary - a.income.salary);

    let count = 0;
    const postUpdatedIncome = employments.map((emp) => {
        if (emp.currentEmployment && count === 0) {
            // Primary Income
            count += 1;
            emp = {
                ...emp,
                employmentType: 'Primary',
                income: {
                    ...emp.income,
                    jobCategory: employmentStatus.retired
                        ? isEmpty(employmentStatus.retirementDate)
                            ? '18'
                            : '20'
                        : emp.income?.jobCategory,
                },
            };
            if (!isAdditional) emp = setDefaultValue(emp, true);
        } else {
            emp = {
                ...emp,
                employmentType: '',
                income: { ...emp.income, jobCategory: emp.income?.jobCategory },
            };

            if (!isAdditional) emp = setDefaultValue(emp, false);
        }

        return emp;
    });

    return postUpdatedIncome;
};

export const getDocumentTypes = (additionIncome: IAdditionalIncome): DocumentTypeEnum[] => {
    const requiredDocuments: DocumentTypeEnum[] = [];

    Object.keys(additionIncome).map((additionalIncomeKey) => {
        const additionalIncomeValue = additionIncome[additionalIncomeKey];
        if (additionalIncomeValue > 0 && !isEmpty(getAdditionalIncomeCode(additionalIncomeKey))) {
            const incomeCode = getAdditionalIncomeCode(additionalIncomeKey);
            requiredDocuments.push(IncomeCodeTypes[Number(incomeCode)]);
        }
    });

    return requiredDocuments;
};
