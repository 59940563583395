import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ON_RESET_EVENT } from 'store/redux/actions';

import { getHousingAsync, getRPOSHousingAsync, updateHousingAsync, updateRPOSHousingAsync } from './actions';
import { CustomerTypeEnum, IHousingInfoResponse, IHousingState, PropertyTypeEnum } from './types';

export const initialState: IHousingState = {
    housing: {
        additionalExpenses: '',
        customerType: null,
        propertyInformation: {
            mortgageInformation: {
                annualCondoFees: '',
                annualHOInsurance: '',
                annualTaxes: '',
                expirationDate: '',
                monthlyPayment: '',
                mortgageFreeAndClear: false,
                mortgageHolder: '',
                presentValue: '10000',
            },
            propertyType: PropertyTypeEnum.NEITHER,
        },
        renterInformation: {
            landLordName: '',
            rentAmount: '',
        },
    },
    loading: true,
};

export const housingSlice = createSlice({
    extraReducers: (builder: any) => {
        builder.addCase(getHousingAsync.pending, (state: any) => {
            state.loading = true;
        });

        builder.addCase(getHousingAsync.fulfilled, (state: any, action: any) => {
            if (action.payload) {
                state.housing = { ...action.payload };

                switch (Number(action.payload.customerType)) {
                    case 1:
                        state.housing.customerType = CustomerTypeEnum.RENTER;
                        state.housing.propertyInformation.propertyType = PropertyTypeEnum.RENT;
                        break;
                    case 2:
                        state.housing.customerType = CustomerTypeEnum.OWNER;
                        state.housing.propertyInformation.propertyType = PropertyTypeEnum.OWN;
                        break;
                    case 4:
                        state.housing.customerType = CustomerTypeEnum.RENTER;
                        state.housing.propertyInformation.propertyType = PropertyTypeEnum.NEITHER;
                        break;
                }
            }
            state.loading = false;
        });
        builder.addCase(getHousingAsync.rejected, (state: any) => {
            state.loading = false;
        });
        builder.addCase(getRPOSHousingAsync.pending, (state: any) => {
            state.loading = true;
        });
        builder.addCase(getRPOSHousingAsync.fulfilled, (state: any, action: PayloadAction<IHousingInfoResponse>) => {
            if (action.payload) {
                state.housing = {
                    ...action.payload,
                    propertyInformation: {
                        ...action.payload.propertyInformation,
                        propertyType: PropertyTypeEnum.NEITHER,
                    },
                };

                switch (Number(action.payload.customerType)) {
                    case 1:
                        state.housing.customerType = CustomerTypeEnum.RENTER;
                        state.housing.propertyInformation.propertyType = PropertyTypeEnum.RENT;
                        break;
                    case 2:
                        state.housing.customerType = CustomerTypeEnum.OWNER;
                        state.housing.propertyInformation.propertyType = PropertyTypeEnum.OWN;
                        break;
                    case 4:
                        state.housing.customerType = CustomerTypeEnum.RENTER;
                        state.housing.propertyInformation.propertyType = PropertyTypeEnum.NEITHER;
                        break;
                }
            }
            state.loading = false;
        });
        builder.addCase(getRPOSHousingAsync.rejected, (state: any) => {
            state.loading = false;
        });
        builder.addCase(updateHousingAsync.fulfilled, (state: any, action: any) => {
            state.housing = { ...action.payload };
        });
        builder.addCase(updateHousingAsync.rejected, (state: any) => {
            state.loading = false;
        });
        builder.addCase(updateRPOSHousingAsync.fulfilled, (state: any, action: any) => {
            state.housing = { ...action.payload };
        });
        builder.addCase(updateRPOSHousingAsync.rejected, (state: any) => {
            state.loading = false;
        });
        builder.addCase(ON_RESET_EVENT, () => initialState);
    },
    initialState,
    name: 'housing',
    reducers: {},
});
