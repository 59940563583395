import 'config/aws';

import React, { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import TagManager from 'react-gtm-module';
import { Loading } from '@fairstone/ui/core/components';
import { configureLogrocket, configureTrackjs } from '@fairstone-frontend/utils/core/logs';
import isEmpty from 'lodash/isEmpty';

import { APP_CONTEXT, CURRENT_ENV, GTM_ID, LOGROCKET_APPNAME, TRACKJS_APPNAME, TRACKJS_KEY } from 'config/constants';
import Providers from 'providers';
import { AppContextEnum } from 'store/redux/modules/types';

import '@fairstone/style/themes/fairstone/main.scss';

configureTrackjs(TRACKJS_APPNAME, TRACKJS_KEY, CURRENT_ENV);

if (!isEmpty(LOGROCKET_APPNAME)) {
    configureLogrocket(LOGROCKET_APPNAME, CURRENT_ENV);
}

const AppOMNI = React.lazy(() => import('AppOMNI'));
const AppRPOS = React.lazy(() => import('AppRPOS'));

const tagManagerArgs = {
    gtmId: GTM_ID,
};

TagManager.initialize(tagManagerArgs);

const container = document.getElementById('root') as HTMLElement;
const root = createRoot(container);

root.render(
    <Providers>
        {APP_CONTEXT === AppContextEnum.OMNI ? (
            <Suspense fallback={<Loading />}>
                <AppOMNI />
            </Suspense>
        ) : (
            <Suspense fallback={<Loading />}>
                <AppRPOS />
            </Suspense>
        )}
    </Providers>
);
