import { DocumentTypeEnum } from '../documentVerification/types';
import { ChannelCodeEnum } from '../types';

export interface IIncomeState {
    employment: Array<IEmployment>;
    pastHistory: Array<IEmployment>;
    additional: IAdditionalIncome;
    employmentStatus: IEmploymentStatus;
    loading: boolean;
    updateProgressStep: IncomeUpdateProgressEnum;
}

export interface IEmploymentStatus {
    retired: boolean;
    retirementDate: string;
}

export interface IGetEmploymentRequestPayload {
    applicationNumber: string;
    channelCode: ChannelCodeEnum | null;
}

export interface IGetRposEmploymentRequestPayload extends IGetEmploymentRequestPayload {
    accountNumber: string;
}

export interface IUpdateEmploymentRequest {
    employment: Array<IEmployment>;
}

export interface IUpdateEmploymentRequestPayload extends IGetEmploymentRequestPayload {
    employment: Array<IEmployment>;
}

export interface IUpdateRposEmploymentRequestPayload extends IGetEmploymentRequestPayload {
    accountNumber: string;
    employments: Array<IEmployment>;
}

export interface IUpdateEmploymentResponsePayload {
    status: string;
}
export interface IGetEmploymentResponsePayload {
    employment: Array<IEmployment>;
}
export interface IGetRposEmploymentResponsePayload {
    employmentInformation: Array<IRposEmployment>;
}
export interface IRposEmployment extends IRposEmploymentBase {
    id: string;
    department: string;
}
export interface IEmployment extends IEmploymentBase {
    id: string;
    department: string;
}

export interface IEmploymentBase {
    currentEmployment: boolean;
    employerName: string;
    employmentEndDate: string;
    employmentStartDate: string;
    employmentType: string;
    income: IEmploymentIncome;
    jobTitle: string;
}

export interface IEmploymentIncome {
    frequency: string;
    incomeSource: string;
    jobCategory: string;
    salary: number;
}

export interface IRposEmploymentBase extends Omit<IEmploymentBase, 'income'> {
    incomeInformation: IEmploymentIncome;
}
export type IAdditionalIncome = Record<string, number>;

export interface IStrToCode {
    string: string;
}

export enum IncomeUpdateProgressEnum {
    CURRENT_EMPLOYMENT,
    END_EMPLOYMENT,
    ADD_HISTORY,
    ADDITIONAL_INCOME,
    PREPARED,
    COMPLETED,
}

export const IncomeCode = {
    alimony: '002',
    childBenefit: '016',
    childSupport: '003',
    disabilitySupport: '004',
    pensionFund: '020',
    pensionPlan: '008',
    rentalIncome: '010',
    retirement: '005',
    unemployment: '017',
    welfareAssistance: '015',
};

export const IncomeCodeTypes: { [key: number]: DocumentTypeEnum } = {
    2: DocumentTypeEnum.ALIMONY,
    3: DocumentTypeEnum.INCOME_OTHER,
    4: DocumentTypeEnum.DISABILITY,
    5: DocumentTypeEnum.RETIREMENT,
    8: DocumentTypeEnum.PENSION,
    10: DocumentTypeEnum.RENTAL,
    15: DocumentTypeEnum.WELFARE,
    16: DocumentTypeEnum.CHILDTAX,
    17: DocumentTypeEnum.UNEMPLOYED,
    20: DocumentTypeEnum.PENSION, // Non-taxable pension fund is also using Pension Document upload section
};

export const incomeSourceList = [
    { amount: 0, checked: false, name: 'alimony', translate: 'alimony' },
    { amount: 0, checked: false, name: 'childSupport', translate: 'child-support' },
    { amount: 0, checked: false, name: 'childBenefit', translate: 'child-benefit' },
    { amount: 0, checked: false, name: 'disabilitySupport', translate: 'disability' },
    { amount: 0, checked: false, name: 'cpp', translate: 'pension-cpp' },
    { amount: 0, checked: false, name: 'oas', translate: 'pension-oas' },
    { amount: 0, checked: false, name: 'qpp', translate: 'pension-qpp' },
    { amount: 0, checked: false, name: 'pensionPlan', translate: 'pension' },
    { amount: 0, checked: false, name: 'rentalIncome', translate: 'rental' },
    { amount: 0, checked: false, name: 'retirement', translate: 'retirement' },
    { amount: 0, checked: false, name: 'welfareAssistance', translate: 'welfare' },
    { amount: 0, checked: false, name: 'unemployment', translate: 'unemployment' },
];
