import { ISegment } from 'types/customer';

export interface IVerifyCustomerRequest {
    firstName: string;
    lastName: string;
    postalCode: string;
    dateOfBirth: string;
    email: string;
}
export interface IVerifyCodeRequest {
    username: string;
    verificationCode: string;
}
export interface IResendCodeRequest {
    username: string;
}
export interface IVerifyCustomerErrorResponse {
    data: {
        error: string;
    };
}
export interface IVerifyCustomerResponse {
    firstName: string;
    verificationKey: string; //kms encrypted token
    ucpId: string;
    error?: string;
}

export interface IRetrieveUsernameResponse {
    username?: string;
    error?: string;
}

export interface ICustomerInformation {
    accountNumber?: string;
    customerId: string;
    day: string;
    email: string;
    firstName: string;
    lastName: string;
    middleName: string;
    month: string;
    postalCode: string;
    year: string;
    username: string;
}

export interface ICustomerState {
    error: string;
    information: ICustomerInformation;
    verificationKey: string;
    ucpId: string;
    loading: boolean;
    segments: ISegment[];
}

export enum EResponseCodes {
    VERIFICATION_FAILED = 422,
    DUPLICATE_FOUND = 409,
    ALREADY_REGISTERED = 452,
    UNCONFIRMED_REGISTRRATION = 453,
    BLOCKED_ACCOUNT = 403,
}
