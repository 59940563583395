import storage from 'redux-persist/lib/storage/session';
import { createBlacklistFilter } from 'redux-persist-transform-filter';

const saveOffersFilter = createBlacklistFilter('offers', ['loading', 'error']);
const saveProductInsuranceFilter = createBlacklistFilter('productInsurance', ['loading', 'error']);
const saveDocumentVerificationFilter = createBlacklistFilter('documentVerification', ['loading', 'error']);
const saveCreditConsentFilter = createBlacklistFilter('creditConsent', ['loading']);
const saveBankFilter = createBlacklistFilter('bank', ['loading']);
const saveApplicationFilter = createBlacklistFilter('application', ['error']);
const saveAddressFilter = createBlacklistFilter('address', ['loading']);
const saveHousingFilter = createBlacklistFilter('housing', ['loading']);
const saveDemographicFilter = createBlacklistFilter('demographic', ['loading']);
const saveIncomeFilter = createBlacklistFilter('income', ['loading']);

export const persistConfig = {
    key: 'root',
    storage,
    transforms: [
        saveOffersFilter,
        saveProductInsuranceFilter,
        saveDocumentVerificationFilter,
        saveCreditConsentFilter,
        saveBankFilter,
        saveApplicationFilter,
        saveAddressFilter,
        saveHousingFilter,
        saveDemographicFilter,
        saveIncomeFilter,
    ],
    version: 1,
};
