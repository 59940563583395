import TrackJS from '@fairstone-frontend/utils/core/logs/trackjs';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';

import { restAPI } from 'services/api';

import { setApplicationError } from '../application';

import {
    IBankExistsPayload,
    IBankExistsResponsePayload,
    IRposStartLoanClosingRequest,
    IRPOSUpdateBankPayload,
    IStartLoanClosingRequestPayload,
    IStartLoanClosingResponsePayload,
    IUpdateBankPayload,
    IUpdateBankResponsePayload,
} from './types';

export const updateBankInfo = async (
    bankInfo: IUpdateBankPayload
): Promise<AxiosResponse<IUpdateBankResponsePayload> | null> => {
    let response: AxiosResponse<IUpdateBankResponsePayload> | null = null;
    try {
        response = await restAPI.post('/user/bank-info', bankInfo);
    } catch (error: any) {
        TrackJS.track(error);
    }

    return response;
};

export const getBankInfo = async (
    payload: IBankExistsPayload
): Promise<AxiosResponse<IBankExistsResponsePayload> | null> => {
    let response: AxiosResponse<IBankExistsResponsePayload> | null = null;

    try {
        response = await restAPI.get('/user/bank-info', { params: { ...payload } });
    } catch (error: any) {
        TrackJS.track(error);
    }

    return response;
};

export const getBankInfoAsync = createAsyncThunk(
    'bank/get-bank-info',
    async (payload: IBankExistsPayload, { rejectWithValue }) => {
        const response = await getBankInfo(payload);

        if (response && response?.data) {
            return {
                infoExists: response?.data.infoExists,
            };
        }

        return rejectWithValue(response);
    }
);

export const updateBankAsync = createAsyncThunk(
    'bank/update-bank-info',
    async (bankInfo: IUpdateBankPayload, { dispatch, rejectWithValue }) => {
        const response = await updateBankInfo(bankInfo);

        if (response && response?.data) {
            return {
                bank: { ...bankInfo },
                updateStatus: response?.data.infoUpdated || false,
            };
        }
        dispatch(setApplicationError(true));
        return rejectWithValue(response);
    }
);

const updateRposBankInfo = async (
    bankInfo: IRPOSUpdateBankPayload
): Promise<AxiosResponse<IUpdateBankResponsePayload> | null> => {
    let response: AxiosResponse<IUpdateBankResponsePayload> | null = null;
    try {
        response = await restAPI.post('/user/bank-info', bankInfo);
    } catch (error: any) {
        TrackJS.track(error);
    }

    return response;
};

export const updateRposBankAsync = createAsyncThunk(
    'bank/rpos-update-bank-info',
    async (bankInfo: IRPOSUpdateBankPayload, { dispatch, rejectWithValue }) => {
        const response = await updateRposBankInfo(bankInfo);

        if (response && response?.data) {
            return {
                bank: { ...bankInfo },
                updateStatus: response?.data.infoUpdated || false,
            };
        }
        dispatch(setApplicationError(true));
        return rejectWithValue(response);
    }
);

const startLoanClosing = async (
    payload: IStartLoanClosingRequestPayload
): Promise<AxiosResponse<IStartLoanClosingResponsePayload> | null> => {
    let response: AxiosResponse<IStartLoanClosingResponsePayload> | null = null;
    try {
        response = await restAPI.post('/offer/start-loan-closing', payload);
    } catch (error: any) {
        TrackJS.track(error);
    }
    return response;
};

export const startLoanClosingAsync = createAsyncThunk(
    '/offer/start-loan-closing',
    async (payload: IStartLoanClosingRequestPayload, { dispatch, rejectWithValue }) => {
        const response = await startLoanClosing(payload);

        if (response && response?.data) {
            return response?.data;
        }
        dispatch(setApplicationError(true));
        return rejectWithValue(response);
    }
);

const startRposLoanClosing = async (
    payload: IRposStartLoanClosingRequest
): Promise<AxiosResponse<IStartLoanClosingResponsePayload> | null> => {
    let response: AxiosResponse<IStartLoanClosingResponsePayload> | null = null;
    try {
        response = await restAPI.post('/offer/start-loan-closing', payload);
    } catch (error: any) {
        TrackJS.track(error);
    }
    return response;
};

export const startRposLoanClosingAsync = createAsyncThunk(
    '/offer/start-rpos-loan-closing',
    async (payload: IRposStartLoanClosingRequest, { dispatch, rejectWithValue }) => {
        const response = await startRposLoanClosing(payload);

        if (response && response?.data) {
            return response?.data;
        }

        dispatch(setApplicationError(true));
        return rejectWithValue(response);
    }
);
