import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { isEmpty } from 'lodash';
import { v4 as uuid4 } from 'uuid';

import { ON_RESET_EVENT } from 'store/redux/actions';
import { isCurrentEmployment } from 'utils/income';

import {
    getAdditionalIncomeCode,
    getEmploymentInfoAsync,
    getRposEmploymentInfoAsync,
    setPrimaryIncome,
    updateEmploymentInfoAsync,
    updateRposEmploymentInfoAsync,
} from './actions';
import {
    IAdditionalIncome,
    IEmployment,
    IEmploymentIncome,
    IEmploymentStatus,
    IGetEmploymentResponsePayload,
    IGetRposEmploymentResponsePayload,
    IIncomeState,
    IncomeUpdateProgressEnum,
    IRposEmployment,
} from './types';

export const updateProgress = createAction<IncomeUpdateProgressEnum>('updateProgress');
export const resetProgress = createAction<IIncomeState>('resetProgress');

export const initAdditionalIncomeState: IAdditionalIncome = {
    alimony: 0,
    childBenefit: 0,
    childSupport: 0,
    cpp: 0,
    disabilitySupport: 0,
    oas: 0,
    pensionFund: 0,
    pensionPlan: 0,
    qpp: 0,
    rentalIncome: 0,
    retirement: 0,
    welfareAssistance: 0,
};

export const initialEmployment: IEmployment = {
    currentEmployment: false,
    department: '',
    employerName: '',
    employmentEndDate: '',
    employmentStartDate: '',
    employmentType: '',
    id: '',
    income: {
        frequency: '',
        incomeSource: '',
        jobCategory: '',
        salary: 0,
    },
    jobTitle: '',
};

const initialIncome: IEmploymentIncome = {
    frequency: '',
    incomeSource: '',
    jobCategory: '',
    salary: 0,
};

export const initEmploymentState: IEmploymentStatus = {
    retired: false,
    retirementDate: '',
};

export const initialState: IIncomeState = {
    additional: initAdditionalIncomeState,
    employment: [],
    employmentStatus: initEmploymentState,
    loading: true,
    pastHistory: [],
    updateProgressStep: IncomeUpdateProgressEnum.CURRENT_EMPLOYMENT,
};

export const incomeSlice = createSlice({
    extraReducers: (builder: any) => {
        builder.addCase(updateProgress, (state: any, action: any) => {
            state.updateProgressStep = action.payload;
        });
        builder.addCase(getEmploymentInfoAsync.pending, (state: any) => {
            state.updateProgressStep = IncomeUpdateProgressEnum.CURRENT_EMPLOYMENT;
            state.loading = true;
        });
        builder.addCase(
            getEmploymentInfoAsync.fulfilled,
            (state: IIncomeState, action: PayloadAction<IGetEmploymentResponsePayload>) => {
                const employments: Array<IEmployment> = action.payload?.employment?.map((emp) => {
                    emp = { ...emp, id: uuid4() };
                    return emp;
                });

                const currentEmployment = employments?.filter((emp) => isCurrentEmployment(emp));

                state.additional = initAdditionalIncomeState;
                state.loading = false;
                state.employment = currentEmployment;
                state.employmentStatus = initEmploymentState;
                state.pastHistory = employments?.filter(
                    (emp) => !isCurrentEmployment(emp) && isEmpty(emp.income?.incomeSource)
                );
                state.updateProgressStep =
                    currentEmployment?.filter((emp) => emp.currentEmployment).length > 0
                        ? IncomeUpdateProgressEnum.CURRENT_EMPLOYMENT
                        : IncomeUpdateProgressEnum.ADD_HISTORY;
            }
        );
        builder.addCase(getEmploymentInfoAsync.rejected, (state: any) => {
            state.loading = false;
        });
        builder.addCase(
            getRposEmploymentInfoAsync.fulfilled,
            (state: IIncomeState, action: PayloadAction<IGetRposEmploymentResponsePayload>) => {
                // Map IRposEmployment -> IEmployment
                const employments: Array<IEmployment> = action.payload?.employmentInformation?.map(
                    (emp: IRposEmployment) =>
                        ({ ...emp, id: uuid4(), income: emp.incomeInformation ?? initialIncome }) as IEmployment
                );

                const currentEmployment =
                    employments?.filter(
                        (employment) =>
                            employment.income?.incomeSource === 'EMP' ||
                            employment.income?.incomeSource === 'AEMP' ||
                            isEmpty(employment.income?.incomeSource)
                    ) ?? [];

                state.additional = initAdditionalIncomeState;
                state.loading = false;
                state.employment = currentEmployment;
                state.employmentStatus = initEmploymentState;
                state.pastHistory = [];
                state.updateProgressStep =
                    currentEmployment?.filter((emp) => emp.currentEmployment).length > 0
                        ? IncomeUpdateProgressEnum.CURRENT_EMPLOYMENT
                        : IncomeUpdateProgressEnum.ADD_HISTORY;
            }
        );
        builder.addCase(getRposEmploymentInfoAsync.pending, (state: any) => {
            state.loading = true;
        });
        builder.addCase(getRposEmploymentInfoAsync.rejected, (state: any) => {
            state.loading = false;
        });
        builder.addCase(updateEmploymentInfoAsync.pending, (state: any) => {
            state.loading = true;
        });
        builder.addCase(updateEmploymentInfoAsync.fulfilled, (state: any) => {
            state.loading = false;
            state.additional = initAdditionalIncomeState;
            state.employmentStatus = initEmploymentState;
            state.updateProgressStep = IncomeUpdateProgressEnum.COMPLETED; //if the result is 200
        });
        builder.addCase(updateEmploymentInfoAsync.rejected, (state: any) => {
            state.loading = false;
        });

        builder.addCase(updateRposEmploymentInfoAsync.pending, (state: any) => {
            state.loading = true;
        });
        builder.addCase(updateRposEmploymentInfoAsync.fulfilled, (state: any) => {
            state.loading = false;
            state.additional = initAdditionalIncomeState;
            state.employmentStatus = initEmploymentState;
            state.updateProgressStep = IncomeUpdateProgressEnum.COMPLETED; //if the result is 200
        });
        builder.addCase(updateRposEmploymentInfoAsync.rejected, (state: any) => {
            state.loading = false;
        });

        builder.addCase(ON_RESET_EVENT, () => initialState);
    },
    initialState,
    name: 'incomeSlice',
    reducers: {
        addAdditionalIncome(state, action: PayloadAction<IAdditionalIncome>) {
            const additionalIncome = action.payload;
            // non-taxable pension = cpp + oas + qpp
            const pensionFund = additionalIncome.cpp + additionalIncome.oas + additionalIncome.qpp;
            state.additional = { ...additionalIncome, pensionFund: pensionFund };
        },
        addEmployment(state, action: PayloadAction<IEmployment>) {
            const updatedEmployments = state.employment.concat(action.payload);
            //primary - secondary calc
            const postUpdatedEmployments = setPrimaryIncome(updatedEmployments, false);
            state.employment = postUpdatedEmployments;
        },
        removeEmployment(state, action: PayloadAction<IEmployment>) {
            const updatedEmployments = state.employment.filter((emp) => emp.id !== action.payload.id);
            //primary - secondary calc
            const postUpdatedEmployments = setPrimaryIncome(updatedEmployments, false);
            state.employment = postUpdatedEmployments;
        },
        updateAdditionalIncome(state, action: PayloadAction<IAdditionalIncome>) {
            const updatedArray: Array<IEmployment> = [];
            const updatedEmployments = state.employment;
            const startDate = new Date().toISOString().substring(0, 10);
            const inputAdditionalIncome = action.payload;

            Object.keys(inputAdditionalIncome).map((additionalIncomeKey) => {
                const additionalIncomeValue = inputAdditionalIncome[additionalIncomeKey];

                if (additionalIncomeValue > 0 && !isEmpty(getAdditionalIncomeCode(additionalIncomeKey))) {
                    const incomeCode = getAdditionalIncomeCode(additionalIncomeKey);

                    const addIncome: IEmployment = {
                        ...initialEmployment,
                        currentEmployment: true,
                        employmentStartDate:
                            additionalIncomeKey === 'retirement' ? state.employmentStatus.retirementDate : startDate,
                        income: {
                            ...initialEmployment.income,
                            frequency: '2',
                            incomeSource: incomeCode,
                            salary: additionalIncomeValue,
                        },
                    };
                    updatedArray.push(addIncome);
                }
            });

            const currentEmployment = updatedEmployments.filter((emp) => emp.currentEmployment);

            if (currentEmployment.length === 0) {
                const setPrimaryArray = setPrimaryIncome(updatedArray, true, state.employmentStatus);
                state.employment = updatedEmployments.concat(setPrimaryArray);
            } else {
                state.employment = updatedEmployments.concat(updatedArray);
            }

            state.updateProgressStep = IncomeUpdateProgressEnum.PREPARED;
        },
        updateCurrentStatus(state, action: PayloadAction<IEmploymentStatus>) {
            state.employmentStatus = action.payload;
        },
        updateEmployment(state, action: PayloadAction<IEmployment>) {
            const updatedEmployments = state.employment.map((emp) =>
                emp.id === action.payload.id ? action.payload : emp
            );
            //primary - secondary calc
            const postUpdatedEmployments = setPrimaryIncome(updatedEmployments, false);
            state.employment = postUpdatedEmployments;
        },
    },
});
